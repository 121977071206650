import Moment from 'moment-timezone';
import { DATE_PATTERN, EMAIL_PATTERN } from '../config/constants';

const isRequired = (value) => (value ? undefined : 'Campo obligatorio');

const validateEmail = (value) => {
  if (value) {
    return EMAIL_PATTERN.test(value) ? undefined : 'Ingrese un email válido';
  }
  return undefined;
};

const validateDateOfBirthday = (value) => {
  if (value) {
    if (!DATE_PATTERN.test(value)) {
      return 'Ingrese una fecha válida. Ejm: dd/mm/yyyy';
    } else {
      let [day, month, year] = value.split('/');

      day = day.length === 1 ? `0${day}` : day;
      month = month.length === 1 ? `0${month}` : month;

      const date = Moment(`${year}-${month}-${day}T00:00:00`).format('X');
      const now = Moment().format('X');

      return parseInt(now) >= parseInt(date)
        ? undefined
        : 'La fecha no puede ser mayor a la fecha actual';
    }
  }

  return undefined;
};

export { isRequired, validateEmail, validateDateOfBirthday };
