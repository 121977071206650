import React, { Fragment } from "react";

const Loader = ({ message = "Cargando" }) => (
  <Fragment>
    <br />
    <br />
    <br />
    <div className="columns is-mobile is-centered">
      <i className="fa fa-4x fa-spinner fa-spin" />
    </div>
    <p className="title is-6 has-text-centered">{message}</p>
    <br />
    <br />
  </Fragment>
);

export default Loader;
