import React, { Component, Fragment } from 'react';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import { clearPayment } from '../../../../actions/Booking';
import { ERROR_STEP_PATH, HOME_PATH } from '../../../../config/paths';
import history from '../../../../history';
import Section from '../../common/Section';
import HomeButton from '../../HomeButton';
import BookingNavbar from '../BookingNavbar';
import { DATE_TIME_FORMAT } from '../../../../config/locale';

class Error extends Component {
  componentDidMount() {
    const { visaTransaction } = this.props;

    if (!visaTransaction.id) {
      history.push(HOME_PATH);
    }
  }

  componentWillUnmount() {
    this.props.dispatchClearPayment();
  }

  render() {
    const {
      visaTransaction: {
        request,
        response,
        reservationId,
        reservationDetailList,
      },
    } = this.props;

    if (!response) {
      return null;
    }

    const data = JSON.parse(response);
    const requestData = JSON.parse(request);
    const createDate = reservationDetailList[0].createDate;

    const {
      data: { ACTION_DESCRIPTION },
    } = data;

    const {
      order: { amount, currency },
    } = requestData;

    const scriptId = 'cse';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;
    script.async = true;
    script.innerHTML =
      "dataLayer.push({'event':'key.event','eventCategory':'confirmacion','eventAction':'view','eventLabel':'error','importe':'" +
      amount +
      "','moneda':'" +
      currency +
      "','descripcion':'" +
      ACTION_DESCRIPTION +
      "','url':'" +
      ERROR_STEP_PATH +
      "'});";

    if (document.getElementById(scriptId)) {
      document.body.removeChild(document.getElementById(scriptId));
    }

    document.body.appendChild(script);

    return (
      <Fragment>
        <Section>
          <BookingNavbar />
          <div className="container" style={{ marginTop: '1.5rem' }}>
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column is-6">
                <div className="card">
                  <div className="card-content">
                    <article className="message is-danger">
                      <div className="message-body">
                        <small>
                          <strong>El pago no se pudo procesar.</strong> <br />{' '}
                          Por favor revisa los datos de tu tarjeta de crédito o
                          comunicate con tu banco emisor e intenta nuevamente.
                        </small>
                      </div>
                    </article>

                    <table className="table is-striped">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Número de pedido</strong>
                          </td>
                          <td>#{reservationId}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Fecha y hora del pedido</strong>
                          </td>
                          <td>
                            {Moment.utc(createDate)
                              .clone()
                              .format(DATE_TIME_FORMAT)}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Descripción de la denegación</strong>
                          </td>
                          <td>{ACTION_DESCRIPTION}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="columns">
                      <div className="column">
                        <HomeButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-3"></div>
            </div>
          </div>
        </Section>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ Booking }) => ({
  visaTransaction: Booking.getIn(['payment', 'visa', 'transaction']),
});

const dispatchToProps = {
  dispatchClearPayment: clearPayment,
};

export default connect(mapStateToProps, dispatchToProps)(Error);
