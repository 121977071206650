import React from 'react';
import Section from './common/Section';
import Container from './common/Container';
import { CAROUSEL_DESTINATIONS_LIST } from '../../config/constants';
import {
  ABOUT_PATH,
  AGENCY_PATH,
  BUS_SERVICES_PATH,
  PARCEL_SERVICES_PATH,
} from '../../config/paths';

import facebookIcon from '../../resources/images/Instabus_Facebook.png';
import instagramIcon from '../../resources/images/Instabus_Instagram.png';
import youtubeIcon from '../../resources/images/Instabus_Youtube.png';

const Footer = () => {
  return (
    <Section cssStyle="section custom-footer bg-white">
      <Container>
        <div className="columns">
          <div className="column has-text-centered-mobile">
            <h6 className="title is-6">Destinos</h6>
            <ul>
              {CAROUSEL_DESTINATIONS_LIST.map((destination) => (
                <li key={destination.id}>
                  <a href={destination.destinationsPath}>{destination.title}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="column has-text-centered-mobile">
            <h6 className="title is-6">Servicios</h6>
            <ul>
              <li>
                <span>
                  <a href={BUS_SERVICES_PATH}>Bus</a>
                </span>
              </li>
              <li>
                <span>
                  <a href={PARCEL_SERVICES_PATH}>Encomiendas y Cargo</a>
                </span>
              </li>
            </ul>
          </div>
          <div className="column has-text-centered-mobile">
            <h6 className="title is-6">Agencias</h6>
            <ul>
              {CAROUSEL_DESTINATIONS_LIST.map((destination) => (
                <li key={destination.id}>
                  <a href={destination.agenciesPath}>
                    Agencia {destination.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="column has-text-centered-mobile">
            <h6 className="title is-6">Instabus</h6>
            <ul>
              <li>
                <a href={ABOUT_PATH}>Nosotros</a>
              </li>
              <li>
                <a href={AGENCY_PATH}>Agencias</a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Instabus.Express"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="no-rounded rrss"
                    src={facebookIcon}
                    alt="Facebook Instabus"
                  />
                </a>{' '}
                <a
                  href="https://www.instagram.com/instabus_express/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="no-rounded rrss"
                    src={instagramIcon}
                    alt="Instagram Instabus"
                  />
                </a>{' '}
                <a
                  href="https://www.youtube.com/channel/UC70RBpW_G5F5k3er_0mwltw/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="no-rounded rrss"
                    src={youtubeIcon}
                    alt="Youtube Instabus"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <p className="has-text-centered is-size-7">Copyright 2020 TRANSPORTES INSTABUS SOCIEDAD ANONIMA CERRADA</p>
      </Container>
    </Section>
  );
};

export default Footer;
