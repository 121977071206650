import { BASE_URL } from './endpoints';

export const HOME_PATH = process.env.REACT_APP_HOME_PATH || '/';
export const ABOUT_PATH = '/nosotros';
export const BUS_SERVICES_PATH = '/servicio-de-bus';
export const PARCEL_SERVICES_PATH = '/servicio-de-encomiendas';
export const DESTINATIONS_PATH = '/destinos-turisticos';
export const AGENCY_PATH = '/agencias';
export const TERMS_AND_CONDITIONS_PATH = '/terminos-y-condiciones';
export const PRIVACY_POLICIES_PATH = '/politicas-de-privacidad';
// Booking paths
export const ITINERARIES_STEP_PATH = '/itinerarios';
export const RETURN_ITINERARIES_STEP_PATH = '/itinerarios-retorno';
export const PASSENGERS_STEP_PATH = '/registro-pasajero';
export const RESULT_STEP_PATH = '/result';
export const CONFIRMATION_STEP_PATH = '/confirmacion';
export const ERROR_STEP_PATH = '/error';
export const PAY_CONFIRMATION_STEP_PATH = '/confirmacion-pago';
export const TIMEOUT_STEP_PATH = '/timeout';

export const PAGE_NOT_FOUND_PATH = '/pagina-no-encontrada';

export const VISA_CONFIRMATION_PATH = `${BASE_URL}/secure/api/confirmation`;
