import React, { Fragment } from "react";
import { toUpperFirst } from "../../../../utils/string";

const ItineraryFilter = ({
  departureTimeFilters,
  arrivalTimeFilters,
  serviceFilters,
  onChange,
}) => {
  let departureTime = null;
  if (departureTimeFilters.length) {
    departureTime = (
      <p>
        <strong>HORA DE SALIDA</strong> <br />
        {departureTimeFilters.map((item) => (
          <label key={`departure-${item.value}`} className="checkbox">
            <input
              type="checkbox"
              filtertype="departure"
              value={item.value}
              label={item.label}
              onChange={onChange}
            />{" "}
            {item.label}
          </label>
        ))}
      </p>
    );
  }
  let arrivalTime = null;
  if (arrivalTimeFilters.length) {
    arrivalTime = (
      <p>
        <strong>HORA DE LLEGADA</strong> <br />
        {arrivalTimeFilters.map((item) => (
          <label key={`arrival-${item.value}`} className="checkbox">
            <input
              type="checkbox"
              filtertype="arrival"
              value={item.value}
              label={item.label}
              onChange={onChange}
            />{" "}
            {item.label}
          </label>
        ))}
      </p>
    );
  }
  let service = null;
  if (serviceFilters.length && serviceFilters.length > 1) {
    service = (
      <p>
        <strong>SERVICIOS</strong> <br />
        {serviceFilters.map((item) => (
          <label key={`service-${item.value}`} className="checkbox">
            <input
              type="checkbox"
              filtertype="service"
              value={item}
              onChange={onChange}
            />{" "}
            {toUpperFirst(item)}
          </label>
        ))}
      </p>
    );
  }
  return (
    <Fragment>
      <p>
        <strong>Filtrar</strong>
      </p>
      <div className="filters content is-small">
        {departureTime}
        {arrivalTime}
        {service}
      </div>
    </Fragment>
  );
};

export default ItineraryFilter;
