import React from 'react';
import Container from './common/Container';
import Section from './common/Section';
import InformationSection from '../website/InformationSection';

const PrivacyPolicies = () => {
  return (
    <>
      <Section cssStyle="section bg-white">
        <Container>
          <h1 className="title has-text-centered">
            Política de Privacidad y Protección de Datos
          </h1>

          <div class="columns mt-4 is-centered has-text-justified">
            <div class="column is-10">
              <div class="content justify-content">
                <p>
                  Transportes Instabus S.A.C pone en conocimiento de los
                  usuarios de la web, la política llevada a cabo respecto al
                  tratamiento de todos los datos de carácter personal que por la
                  utilización de las funciones de la aplicación se faciliten a
                  la Empresa.
                </p>
                <br />
                <p>
                  <strong>BANCO DE DATOS PERSONALES</strong>
                  <br />
                  Transportes Instabus S.A.C informa a los usuarios de la web de
                  la existencia de un Banco automatizado de datos personales
                  cuyo responsable es Transportes Instabus S.A.C
                  <br />
                  <br />
                  Todos los datos que se solicitan a los usuarios a través de la
                  web serán necesarios para prestar el servicio relacionado con
                  el transporte de pasajeros en virtud del cual se ha procedido
                  al uso de la web.
                </p>
                <br />
                <p>
                  <strong>CONSENTIMIENTO</strong> <br />
                  La utilización de la aplicación dará lugar al tratamiento de
                  datos de carácter personal que Transportes Instabus S.A.C
                  llevará a cabo de acuerdo con las normas y procedimientos
                  internos establecidos al efecto, que son conocidos por los
                  usuarios y autorizados por éstos.
                </p>
                <br />
                <p>
                  <strong>GARANTÍA Y PROTECCIÓN DE DATOS</strong> <br />
                  En el tratamiento de los datos de carácter personal,
                  Transportes Instabus S.A.C se compromete a garantizar y
                  proteger las libertades públicas y los derechos fundamentales
                  de las personas registradas en el Banco de Datos, obligándose
                  en este sentido, a efectuar el correspondiente tratamiento de
                  datos de acuerdo con la normativa vigente en cada momento y a
                  guardar el más absoluto secreto en relación con la información
                  entregada por los clientes y usuarios.
                  <br />
                  <br />
                  Los datos de carácter personal objeto de tratamiento no se
                  utilizarán para otras finalidades que no se encuentren aquí
                  recogidas o, en su caso, por otro documento o contrato que
                  vinculase a ambas partes con condiciones particulares.
                </p>
                <br />
                <p>
                  <strong>
                    EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN,
                    CANCELACIÓN Y OPOSICIÓN
                  </strong>{' '}
                  <br />
                  Transportes Instabus S.A.C informa de que los usuarios podrán
                  ejercitar los derechos de acceso, rectificación, cancelación y
                  oposición mediante el envío de un correo electrónico a
                  atencionalcliente@instabus.com.pe
                </p>
                <br />
                <p>
                  <strong>MEDIDAS DE SEGURIDAD</strong> <br />
                  Transportes Instabus S.A.C informa a los usuarios que ha
                  adoptado las medidas de índole técnica y organizativas
                  necesarias para garantizar la seguridad de los datos de
                  carácter personal y evitar la alteración, pérdida, tratamiento
                  o acceso no autorizado, habida cuenta del estado de la
                  tecnología, la naturaleza de los datos almacenados y los
                  riesgos a que están expuestos. Igualmente Transportes Instabus
                  S.A.C garantiza al usuario el cumplimiento del deber de
                  secreto profesional respecto de los datos personales de los
                  usuarios y del deber de guardarlos.{' '}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <InformationSection />
    </>
  );
};

export default PrivacyPolicies;
