import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CONFIRMATION_STEP_PATH,
  ERROR_STEP_PATH,
  PAY_CONFIRMATION_STEP_PATH,
} from "../../../../config/paths";
import history from "../../../../history";
import Loader from "../../common/Loader";
import { changeStep, getVisaTransaction } from "../../../../actions";
import { CONFIRMATION_STEP_INDEX } from "../../../../config/constants";

class Result extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchGetVisaTransaction,
      dispatchChangeStep,
    } = this.props;
    dispatchChangeStep(CONFIRMATION_STEP_INDEX);
    dispatchGetVisaTransaction({ id });
  }

  componentDidUpdate() {
    const {
      visaTransaction: { statusCode, response },
    } = this.props;

    if (statusCode.indexOf("200") >= 0) {
      if (response === "Ocurrió un error al generar el boleto") {
        history.push(PAY_CONFIRMATION_STEP_PATH);
      } else {
        history.push(CONFIRMATION_STEP_PATH);
      }
    }
    if (statusCode.indexOf("400") >= 0) {
      history.push(ERROR_STEP_PATH);
    }
  }

  render() {
    return <Loader />;
  }
}

const mapStateToProps = ({ Booking }) => ({
  visaTransaction: Booking.getIn(["payment", "visa", "transaction"]),
});

const mapDispatchToProps = {
  dispatchGetVisaTransaction: getVisaTransaction,
  dispatchChangeStep: changeStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
