import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import {
  ITINERARY_ENDPOINT,
  BUS_OCUPABILITY_ENDPOINT,
  SEAT_RESERVATION_ENDPOINT,
  VISA_AUTHORIZATION_ENDPOINT,
  generateSeatReservationRevertEndpoint,
  GET_SESSION_ENDPOINT,
} from '../config/endpoints';
import {
  FLAG_SEARCHING_FOR_ITINERARIES,
  SEARCH_FOR_ITINERARIES,
  FLAG_GET_SEATS,
  BUS_OCUPABILITY,
  CLEAR_BUS_OCUPABILITY,
  FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
  SELECT_SEAT,
  DESELECT_SEAT,
  SELECT_ITINERARY,
  CHANGE_STEP,
  CLEAR_ITINERARIES,
  CLEAR_SEAT_SELECTIONS,
  SEAT_RESERVATION,
  PAYMENT_VISA_SESSION,
  CLEAR_PAYMENT,
  GET_VISA_TRANSACTION,
} from './types';
import {
  DEFAULT_GET_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../config/rest';
import { createCustomer, obtainCustomer } from './Customer';
import history from '../history';
import { HOME_PATH } from '../config/paths';
import { createBusiness } from './Business';
import validateResponse from '../utils/response-handler';
import getClientIp from '../utils/ip';
import { PASSENGER_DOCUMENT_TYPE_OPTIONS } from '../config/constants';

const flagSearchingForItineraries = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_SEARCHING_FOR_ITINERARIES,
    payload: flag,
  });

const flagGetSeats = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GET_SEATS,
    payload: flag,
  });

const searchForItineraries = ({
  source,
  sourceId,
  destination,
  destinationId,
  date,
  returnDate,
  hasReturn,
}) => async (dispatch) => {
  try {
    dispatch(flagSearchingForItineraries(true));

    let payload = {
      sourceLocation: source,
      destinationLocation: destination,
      date,
    };

    if (hasReturn) {
      payload = {
        sourceLocation: destination,
        destinationLocation: source,
        date: returnDate,
      };
    }

    const url = `${ITINERARY_ENDPOINT}?${QueryString.stringify(payload)}`;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await validateResponse(response);

    const itineraries = await response.json();

    dispatch({
      type: SEARCH_FOR_ITINERARIES,
      payload: {
        source,
        sourceId,
        destination,
        destinationId,
        date,
        returnDate,
        hasReturn,
        results: itineraries,
      },
    });
  } catch (error) {
    toastr.error(error.message);
  } finally {
    dispatch(flagSearchingForItineraries(false));
  }
};

const busOcupability = ({ itineraryId, routeId }) => async (dispatch) => {
  try {
    dispatch(flagGetSeats(true));

    const payload = {
      itineraryId,
      routeId,
    };

    const url = `${BUS_OCUPABILITY_ENDPOINT}?${QueryString.stringify(payload)}`;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await validateResponse(response);

    const seatMap = await response.json();

    dispatch({
      type: BUS_OCUPABILITY,
      payload: seatMap,
    });
  } catch (error) {
    toastr.error(error.message);
  } finally {
    dispatch(flagGetSeats(false));
  }
};

const clearBusOcupability = () => (dispatch) =>
  dispatch({ type: CLEAR_BUS_OCUPABILITY });

const clearSeatSelections = () => (dispatch) =>
  dispatch({ type: CLEAR_SEAT_SELECTIONS });

const clearItineraries = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARIES,
  });

const flagChangingSeatSelection = ({ status, seatId }) => (dispatch) =>
  dispatch({
    payload: { status, seatId },
    type: FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
  });

const selectSeat = ({
  itineraryId,
  routeId,
  seatNumber,
  floorNumber,
  price,
  seatId,
}) => async (dispatch, getState) => {
  try {
    dispatch(
      flagChangingSeatSelection({
        status: 'start',
        seatId,
      })
    );
    await new Promise((resolve) => setTimeout(resolve, 250));
    dispatch({
      type: SELECT_SEAT,
      payload: {
        itineraryId,
        seatNumber,
        floorNumber,
        price,
        seatId,
        routeId,
      },
    });
  } catch (error) {
    toastr.error(error.message);
  } finally {
    dispatch(
      flagChangingSeatSelection({
        status: 'false',
        seatId,
      })
    );
  }
};

const deselectSeat = ({ seatId }) => async (dispatch) => {
  try {
    dispatch(
      flagChangingSeatSelection({
        status: 'start',
        seatId,
      })
    );
    await new Promise((resolve) => setTimeout(resolve, 250));
    dispatch({
      type: DESELECT_SEAT,
      payload: seatId,
    });
  } catch (error) {
    toastr.error(error.message);
  } finally {
    dispatch(
      flagChangingSeatSelection({
        status: 'false',
        seatId,
      })
    );
  }
};

const selectItinerary = ({ time, serviceType, arrivalDate, arrivalTime }) => (
  dispatch
) =>
  dispatch({
    type: SELECT_ITINERARY,
    payload: { time, serviceType, arrivalDate, arrivalTime },
  });

const changeStep = (step) => (dispatch) => {
  dispatch({
    type: CHANGE_STEP,
    payload: step,
  });
};

const getSession = async ({
  amount,
  antifraud,
  recurrenceMaxAmount,
  request,
  purchaseNumber,
}) => {
  const payload = {
    amount,
    antifraud,
    recurrenceMaxAmount,
    request,
    reservationId: purchaseNumber,
  };

  const url = GET_SESSION_ENDPOINT;

  const response = await fetch(url, {
    ...DEFAULT_POST_CONFIG,
    body: JSON.stringify(payload),
  });

  await validateResponse(response);

  return await response.json();
};

const seatReservation = ({
  reservationRequestList,
  business,
  invoice,
}) => async (dispatch, getState) => {
  try {
    dispatch(flagGetSeats(true));

    let businessId = business && business.id ? business.id : null;

    // Si pide factura y el RUC no existe
    if (invoice && !businessId) {
      const { id } = await createBusiness(business);
      businessId = id;
    }

    const newReservationRequestList = [...reservationRequestList];

    // Obtener y registrar cliente
    let index = 0;
    let amount = 0;
    let cardholderName = '';
    let cardholderLastname = '';
    let cardholderEmail = '';
    let purchaseNumber = 0;
    let documentNumber = '';
    let documentType = '';

    for (const reservationRequest of reservationRequestList) {
      const {
        passenger,
        seatInformation: { price },
      } = reservationRequest;

      amount += parseFloat(price);

      let customer = null;

      // Cliente nuevo
      if (!passenger.customerId) {
        const { documentTypeId, documentNumber } = passenger;

        // Busca cliente
        customer = await obtainCustomer({ documentTypeId, documentNumber });

        // Si encuentra cliente asigno customerId a object passenger
        if (customer.customerId) {
          passenger.customerId = customer.customerId;
        }
      }

      customer = await createCustomer(passenger);

      newReservationRequestList[index].passenger.customerId =
        customer.customerId;

      if (index === 0) {
        cardholderName = passenger.name;
        cardholderLastname = passenger.lastName;
        cardholderEmail = passenger.email;
        documentNumber = passenger.documentNumber;

        const documentTypeObject = PASSENGER_DOCUMENT_TYPE_OPTIONS.find(
          (type) => type.value === passenger.documentTypeId
        );

        documentType = documentTypeObject.label;

        if (documentType === 'CE') documentType = 'CEX';
        if (documentType === 'Pasaporte') documentType = 'PAS';
        if (invoice) documentType = 'RUC';
      }

      newReservationRequestList[index].businessId = businessId;

      index += 1;
    }

    const payload = newReservationRequestList;

    // Obtenemos reservation del store
    const storeReservation = getState()
      .Booking.getIn(['seats', 'seatReservation'])
      .toJS();

    // Si reservation no existe lo creamos
    if (!storeReservation.id) {
      const url = SEAT_RESERVATION_ENDPOINT;

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await validateResponse(response);

      const reservation = await response.json();

      dispatch({
        type: SEAT_RESERVATION,
        payload: reservation,
      });

      purchaseNumber = reservation.id;
    } else {
      purchaseNumber = storeReservation.id;
    }

    // Armamos el antifraud
    const trip = getState().Booking.getIn(['search', 'query']).toJS();

    const origin = trip.source.substring(0, 3);

    const destination = trip.destination.substring(0, 3);

    const route = `${origin}-${destination}`;

    const clientIp = await getClientIp();

    const antifraud = {
      clientIp,
      merchantDefineDataString: JSON.stringify({
        MDD4: cardholderEmail,
        MDD6: 0,
        MDD18: route,
        MDD19: origin,
        MDD20: destination,
        MDD21: 0,
        MDD30: documentNumber,
        MDD32: documentNumber,
        MDD63: documentType,
        MDD65: documentNumber,
        MDD75: 'Invitado',
        MDD77: 1,
      }),
    };

    const request = {
      captureType: 'manual',
      channel: 'web',
      countable: true,
      order: { amount, currency: 'PEN', purchaseNumber, tokenId: null },
    };

    const { visaSessionResponse, visaSessionResponseError } = await getSession({
      amount,
      antifraud,
      recurrenceMaxAmount: null,
      request: JSON.stringify(request),
      purchaseNumber,
    });

    if (!visaSessionResponse && visaSessionResponseError) {
      const error = {
        statusCode: visaSessionResponseError.errorCode,
        status: null,
        message: visaSessionResponseError.errorMessage || 'Error',
      };

      throw error;
    }

    if (visaSessionResponse) {
      const session = {
        amount,
        cardholderName,
        cardholderLastname,
        cardholderEmail,
        purchaseNumber,
        sessionKey: visaSessionResponse.sessionKey,
      };

      dispatch({
        type: PAYMENT_VISA_SESSION,
        payload: session,
      });
    }
  } catch (error) {
    toastr.error(error.message);
  } finally {
    dispatch(flagGetSeats(false));
  }
};

const clearPayment = () => (dispatch) => {
  dispatch({ type: CLEAR_PAYMENT });
};

const getVisaTransaction = ({ id }) => async (dispatch) => {
  try {
    const url = `${VISA_AUTHORIZATION_ENDPOINT}/${id}`;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await validateResponse(response);

    const transaction = await response.json();

    dispatch({
      type: GET_VISA_TRANSACTION,
      payload: transaction,
    });
  } catch (error) {
    dispatch(clearPayment());
    toastr.error(error.message);
    history.push(HOME_PATH);
  }
};

const seatReservationRevert = ({ reservationId }) => async (dispatch) => {
  try {
    const url = generateSeatReservationRevertEndpoint(reservationId);

    const response = await fetch(url, DEFAULT_PUT_CONFIG);

    await validateResponse(response);

    return await response.json();
  } catch (error) {
    toastr.error(error.message);
    return null;
  }
};

export {
  searchForItineraries,
  busOcupability,
  clearBusOcupability,
  selectSeat,
  deselectSeat,
  selectItinerary,
  changeStep,
  clearItineraries,
  clearSeatSelections,
  seatReservation,
  clearPayment,
  getVisaTransaction,
  seatReservationRevert,
};
