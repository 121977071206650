import React from "react";

const SelectedItineraryFilter = ({ filters }) => {
  if (filters.length === 0) {
    return null;
  }
  return (
    <div className="tags is-hidden-mobile">
      {filters.map((filter) => {
        let filterType = null;
        switch (filter.filterType) {
          case "departure":
            filterType = <strong>Salida:</strong>;
            break;
          case "arrival":
            filterType = <strong>Llegada:</strong>;
            break;
          default:
            break;
        }
        return (
          <span className="tag is-white">
            {filterType} {filter.label}
          </span>
        );
      })}
    </div>
  );
};

export default SelectedItineraryFilter;
