import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment-timezone';
import { CONFIRMATION_STEP_PATH, HOME_PATH } from '../../../../config/paths';
import history from '../../../../history';
import Section from '../../common/Section';
import BookingNavbar from '../BookingNavbar';
import { clearPayment } from '../../../../actions/Booking';
import HomeButton from '../../HomeButton';
import { DATE_TIME_FORMAT } from '../../../../config/locale';
import { toUpperFirst } from '../../../../utils/string';

class Confirmation extends Component {
  componentDidMount() {
    const { visaTransaction } = this.props;

    if (!visaTransaction.id) {
      history.push(HOME_PATH);
    }
  }

  componentWillUnmount() {
    this.props.dispatchClearPayment();
  }

  render() {
    const {
      visaTransaction: { response, reservationId, reservationDetailList },
    } = this.props;

    if (!response) {
      return null;
    }

    const data = JSON.parse(response);
    const reservation = JSON.parse(reservationDetailList[0].payload);
    const createDate = reservationDetailList[0].createDate;
    const description = 'Boleto de bus';

    const {
      order: { amount, currency },
      dataMap: { BRAND, CARD },
    } = data;

    const scriptId = 'csc';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;
    script.async = true;
    script.innerHTML =
      "dataLayer.push({'event':'key.event','eventCategory':'confirmacion','eventAction':'view','eventLabel':'exito','importe':'" +
      amount +
      "','moneda':'" +
      currency +
      "','descripcion':'" +
      description +
      "','url':'" +
      CONFIRMATION_STEP_PATH +
      "'});";

    if (document.getElementById(scriptId)) {
      document.body.removeChild(document.getElementById(scriptId));
    }

    document.body.appendChild(script);

    return (
      <Fragment>
        <Section>
          <BookingNavbar />
          <div className="container" style={{ marginTop: '1.5rem' }}>
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column is-6">
                <div className="card">
                  <div className="card-content">
                    <h6 className="title is-6 ib-secondary">
                      ¡Muchas gracias por tu compra!
                    </h6>

                    <article className="message is-success">
                      <div className="message-body">
                        <small>
                          <strong>
                            El pago se ha procesado correctamente.
                          </strong>{' '}
                          <br />
                          En breve recibirán un correo de confirmación en con
                          tus boletos electrónicos.
                        </small>
                      </div>
                    </article>

                    <table className="table is-striped">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Número de pedido</strong>
                          </td>
                          <td>#{reservationId}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Nombre y apellido</strong>
                          </td>
                          <td>
                            {reservation.passenger.name}{' '}
                            {reservation.passenger.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Fecha y hora del pedido</strong>
                          </td>
                          <td>
                            {Moment.utc(createDate)
                              .clone()
                              .format(DATE_TIME_FORMAT)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Importe de la transacción</strong>
                          </td>
                          <td>S/ {amount}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Tipo de moneda</strong>
                          </td>
                          <td>{currency}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Descripción del producto</strong>
                          </td>
                          <td>{description}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Marca</strong>
                          </td>
                          <td>{toUpperFirst(BRAND)}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Número de tarjeta</strong>
                          </td>
                          <td>{CARD}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="columns">
                      <div className="column">
                        <HomeButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-3"></div>
            </div>
          </div>
        </Section>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ Booking }) => ({
  visaTransaction: Booking.getIn(['payment', 'visa', 'transaction']),
});

const dispatchToProps = {
  dispatchClearPayment: clearPayment,
};

export default connect(mapStateToProps, dispatchToProps)(Confirmation);
