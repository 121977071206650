import React from "react";

const InformationSectionColumn = ({
  imageSrc,
  imageAlt,
  title,
  description,
}) => {
  return (
    <div className="column is-3 has-text-centered">
      <figure className="image is-48x48 image-info">
        <img className="no-rounded" src={imageSrc} alt={imageAlt} />
      </figure>
      <h5 className="title is-5">{title}</h5>
      <p>{description}</p>
    </div>
  );
};

export default InformationSectionColumn;
