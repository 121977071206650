import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import { BUSINESS_ENDPOINT } from '../config/endpoints';
import { CLEAR_BUSINESS, GET_BUSINESS, FLAG_GETTING_BUSINESS } from './types';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../config/rest';
import validateResponse from '../utils/response-handler';

const flagGettingBusiness = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_BUSINESS,
    payload: flag,
  });

const obtainBusiness = async ({ businessTaxId }) => {
  const payload = { businessTaxId };

  const url = `${BUSINESS_ENDPOINT}?${QueryString.stringify(payload)}`;
  const response = await fetch(url, DEFAULT_GET_CONFIG);

  await validateResponse(response);

  const business = await response.json();

  return business;
};

const getBusiness = ({ businessTaxId }) => async (dispatch) => {
  try {
    dispatch(flagGettingBusiness(true));

    const business = await obtainBusiness({ businessTaxId });

    dispatch({
      type: GET_BUSINESS,
      payload: business,
    });
  } catch (error) {
    toastr.error(error.message);
  } finally {
    dispatch(flagGettingBusiness(false));
  }
};

const createBusiness = async ({
  businessTaxId,
  name,
  addressSummary,
  idCountryOfOrigin,
}) => {
  try {
    const payload = {
      businessTaxId,
      name,
      addressSummary,
      idCountryOfOrigin,
    };

    const response = await fetch(BUSINESS_ENDPOINT, {
      ...DEFAULT_POST_CONFIG,
      body: JSON.stringify(payload),
    });

    await validateResponse(response);

    const business = await response.json();
    return business;
  } catch (error) {
    return null;
  }
};

const clearBusiness = () => (dispatch) =>
  dispatch({
    type: CLEAR_BUSINESS,
  });

export { getBusiness, createBusiness, clearBusiness, obtainBusiness };
