import { toastr } from 'react-redux-toastr';
import { CITY_ENDPOINT } from '../config/endpoints';
import { CLEAR_CITIES, GET_CITIES, FLAG_GETTING_CITIES } from './types';
import { DEFAULT_GET_CONFIG } from '../config/rest';
import validateResponse from '../utils/response-handler';

const flagGettingCities = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CITIES,
    payload: flag,
  });

const getCities = () => async (dispatch) => {
  try {
    dispatch(flagGettingCities(true));

    const url = CITY_ENDPOINT;

    const response = await fetch(url, DEFAULT_GET_CONFIG);

    await validateResponse(response);

    const cities = await response.json();

    dispatch({
      type: GET_CITIES,
      payload: cities,
    });
  } catch (error) {
    toastr.error(error.message);
  } finally {
    dispatch(flagGettingCities(false));
  }
};

const clearCities = () => (dispatch) =>
  dispatch({
    type: CLEAR_CITIES,
  });

export { getCities, clearCities };
