import React, { Component } from "react";
import { connect } from "react-redux";
import SeatMapElement from "./SeatMapElement";

export class Seat extends Component {
  constructor(props) {
    super(props);

    this.handleSeatClick = this.handleSeatClick.bind(this);
    this.handleSeatKeyPress = this.handleSeatKeyPress.bind(this);
    this.handleSeatInteraction = this.handleSeatInteraction.bind(this);
    this.isFree = this.isFree.bind(this);
  }

  isFree() {
    return (
      this.props.seatObjectType === "SEAT" && this.props.status === "AVAILABLE"
    );
  }

  handleSeatClick() {
    this.handleSeatInteraction();
  }

  handleSeatKeyPress(event) {
    if (event.key === "Enter") {
      this.handleSeatInteraction();
    }
  }

  isSelected = () => {
    const {
      seatSelections,
      returnSeatSelections,
      hasReturn,
      id: seatId,
    } = this.props;

    let selected = null;
    if (hasReturn) {
      selected = returnSeatSelections.find(
        (seatReservation) => seatReservation.seatId === seatId
      );
    } else {
      selected = seatSelections.find(
        (seatReservation) => seatReservation.seatId === seatId
      );
    }

    return selected ? true : false;
  };

  isTransitioning = () => {
    const { seatSelectionChangesInProgress, id: seatId } = this.props;
    return seatSelectionChangesInProgress.find(
      (seatSelectionChange) => seatSelectionChange === seatId
    );
  };

  handleSeatInteraction() {
    const {
      handleSeatSelection,
      handleSeatDeselection,
      itineraryId,
      routeId,
      seatNumber,
      floorNumber,
      price,
      id: seatId,
      hasReturn,
      seatSelections: { size: seatSelectionsSize },
      returnSeatSelections: { size: returnSeatSelectionsSize },
    } = this.props;

    const seatSelectionFunction = () =>
      handleSeatSelection({
        itineraryId,
        routeId,
        seatNumber,
        floorNumber,
        price,
        seatId,
      });
    const seatDeselectionFunction = () => handleSeatDeselection({ seatId });

    if (this.isFree()) {
      if (!this.isSelected()) {
        if (hasReturn) {
          if (returnSeatSelectionsSize < seatSelectionsSize) {
            seatSelectionFunction();
          }
        } else {
          seatSelectionFunction();
        }
      } else {
        seatDeselectionFunction();
      }
    }
  }

  render() {
    const { seatObjectType, seatNumber, status, id } = this.props;
    const free = status === "AVAILABLE";
    const selected = this.isSelected();
    if (this.isTransitioning()) {
      return (
        <div id={`id-${id}`} className="icon" style={{ marginLeft: "5px" }}>
          <i className="fa fa-2x fa-spinner fa-spin" />
        </div>
      );
    }
    return (
      <SeatMapElement
        seatNumber={seatNumber}
        onClick={this.handleSeatClick}
        onKeyPress={this.handleSeatKeyPress}
        seatObjectType={seatObjectType}
        id={`id-${id}`}
        selected={selected}
        free={free}
      />
    );
  }
}

export default connect()(Seat);
