export const SEARCH_FOR_ITINERARIES = "SEARCH_FOR_ITINERARIES";
export const FLAG_SEARCHING_FOR_ITINERARIES = "FLAG_SEARCHING_FOR_ITINERARIES";
export const CLEAR_ITINERARIES = "CLEAR_ITINERARIES";

export const BUS_OCUPABILITY = "BUS_OCUPABILITY";
export const CLEAR_BUS_OCUPABILITY = "CLEAR_BUS_OCUPABILITY";

export const SELECT_ITINERARY = "SELECT_ITINERARY";
export const SELECT_SEAT = "SELECT_SEAT";
export const DESELECT_SEAT = "DESELECT_SEAT";
export const FLAG_GET_SEATS = "FLAG_GET_SEATS";
export const CLEAR_SEAT_SELECTIONS = "CLEAR_SEAT_SELECTIONS";
export const FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS =
  "FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS";
export const SEAT_RESERVATION = "SEAT_RESERVATION";
export const CLEAR_SEAT_RESERVATION = "CLEAR_SEAT_RESERVATION";

export const PAYMENT_VISA_TOKEN = "PAYMENT_VISA_TOKEN";
export const PAYMENT_VISA_SESSION = "PAYMENT_VISA_SESSION";
export const CLEAR_PAYMENT = "CLEAR_PAYMENT";

export const GET_VISA_TRANSACTION = "GET_VISA_TRANSACTION";

export const CHANGE_STEP = "CHANGE_STEP";
export const RESET_STEP = "RESET_STEP";
