import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { toUpperFirst } from "../../../../utils/string";
import { FULL_DATE_TIME_FORMAT } from "../../../../config/locale";

class PurchaseDetail extends Component {
  renderDetail = () => {
    const {
      source,
      destination,
      date,
      time,
      serviceType,
      seatSelections,
      returnDate,
      returnTime,
      returnServiceType,
      returnSeatSelections,
      hasReturn,
    } = this.props;

    const goingFormattedDate = Moment.utc(`${date} ${time}`)
      .clone()
      .format(FULL_DATE_TIME_FORMAT);

    let goingTotal = 0;
    seatSelections.forEach((seat) => {
      goingTotal += parseFloat(seat.price);
    });

    let returnDetail = null;
    let returnTotal = 0;
    if (hasReturn) {
      const returnFormattedDate = Moment.utc(`${returnDate} ${returnTime}`)
        .clone()
        .format(FULL_DATE_TIME_FORMAT);

      returnSeatSelections.forEach((seat) => {
        returnTotal += parseFloat(seat.price);
      });

      returnDetail = (
        <Fragment>
          <div className="column is-three-fifths">
            <div>Viaje para {returnSeatSelections.length} adultos</div>
            <small>{returnServiceType}</small>
          </div>
          <div className="column ib-third has-text-right">
            <p>
              <small>S/&nbsp;</small>
              {returnTotal.toFixed(2)}
            </p>
          </div>
          <div className="column is-full is-detail" style={{ paddingTop: 0 }}>
            <p className="has-text-weight-semibold ib-primary">
              {toUpperFirst(destination)}
              <span
                className="has-text-weight-semibold ib-primary"
                dangerouslySetInnerHTML={{ __html: " &#8594 " }}
              />
              {toUpperFirst(source)}
            </p>
            <p>
              <small>Fecha y hora de salida: </small>
              <br />
              <small>{returnFormattedDate}</small>
            </p>
          </div>
        </Fragment>
      );
    }

    const total = goingTotal + returnTotal;

    return (
      <Fragment>
        <div className="column is-three-fifths">
          <div>Viaje para {seatSelections.length} adultos</div>
          <small>{serviceType}</small>
        </div>
        <div className="column ib-third has-text-right">
          <p>
            <small>S/&nbsp;</small>
            {goingTotal.toFixed(2)}
          </p>
        </div>
        <div className="column is-full is-detail" style={{ paddingTop: 0 }}>
          <p className="has-text-weight-semibold ib-primary">
            {toUpperFirst(source)}
            <span
              className="has-text-weight-semibold ib-primary"
              dangerouslySetInnerHTML={{ __html: " &#8594 " }}
            />
            {toUpperFirst(destination)}
          </p>
          <p>
            <small>Fecha y hora de salida: </small>
            <br />
            <small>{goingFormattedDate}</small>
          </p>
        </div>
        {returnDetail}
        <div className="column is-half is-detail">
          <p className="title is-6 ib-third">
            <small> Total a pagar</small>
          </p>
        </div>
        <div className="column is-half is-detail has-text-right">
          <p className="title is-6 ib-third">
            <small>S/&nbsp;</small>
            <strong>{total.toFixed(2)}</strong>
          </p>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <div className="card">
        <div className="card-content">
          <div className="content">
            <div className="columns is-multiline is-mobile">
              <div className="column is-full is-detail">
                <p className="has-text-weight-semibold ib-primary">
                  Detalle de tu compra
                </p>
              </div>
              {this.renderDetail()}
            </div>

            <p>
              <small>Equipaje de mano máximo 8kg</small> <br />
              <small>Equipaje de bodega máximo 20 kg</small>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Booking }) => ({
  source: Booking.getIn(["search", "query", "source"]),
  destination: Booking.getIn(["search", "query", "destination"]),
  seatSelections: Booking.getIn(["seats", "seatSelections"]).toJS(),
  date: Booking.getIn(["search", "query", "date"]),
  time: Booking.getIn(["search", "query", "time"]),
  serviceType: Booking.getIn(["search", "query", "serviceType"]),
  returnSeatSelections: Booking.getIn(["seats", "returnSeatSelections"]).toJS(),
  returnDate: Booking.getIn(["search", "query", "returnDate"]),
  returnTime: Booking.getIn(["search", "query", "returnTime"]),
  returnServiceType: Booking.getIn(["search", "query", "returnServiceType"]),
  hasReturn: Booking.getIn(["search", "query", "hasReturn"]),
});

export default connect(mapStateToProps)(PurchaseDetail);
