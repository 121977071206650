export const TIME_ZONE = "America/Lima";
export const FULL_DATE_FORMAT = "dddd, DD [de] MMMM";
export const FULL_DATE_TIME_FORMAT = "dddd, DD [de] MMMM [de] YYYY hh:mma";
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_EN_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
export const TIME_FORMAT = "HH:mm";
export const FULL_TIME_FORMAT = "hh:mma";
export const CURRENCY = "PEN";
export const LOCALE = "es";
