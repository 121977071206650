import React from 'react';
import Container from './common/Container';
import Section from './common/Section';
import InformationSection from './InformationSection';
import SearchFormSection from './SearchFormSection';

import LimaJpg from '../../resources/images/Lima_256x256px.jpg';
import HuaralJpg from '../../resources/images/Huaral_256x256px.jpg';
import HuachoJpg from '../../resources/images/Huacho_256x256px.jpg';
import SupeJpg from '../../resources/images/Supe_256x256px.jpg';
import BarrancaJpg from '../../resources/images/Barranca_256x256px.jpg';
import ParamongaJpg from '../../resources/images/Paramonga_256x256px.jpg';

const Destinations = () => {
  return (
    <>
      <SearchFormSection />

      <Section cssStyle="section bg-white">
        <Container>
          <div className="columns">
            <div className="column is-12">
              <p className="title is-3 is-spaced">
                Descubre lo que el norte chico tiene para ti
              </p>
              <p className="subtitle is-5">
                Vive la experiencia y recorre todos los lugares deslumbrantes
                que el norte chico te ofrece. ¡Te encantarán!
              </p>
              <p>
                ¿Estás en duda a dónde ir o qué hacer en tu próximo viaje? Elige
                entre los 6 destinos que tenemos en Instabus y conoce más sobre
                cada ciudad: Lima, Huaral, Chancay, Huacho, Supe, Barranca y
                Paramonga. Cada lugar con su propio encanto, historia y gente
                harán que tu viaje sea uno de los mejores momentos para
                recordar. Así que, si estás ideando tu próximo escape, es hora
                que coloques en tu agenda a estas ciudades entre tus opciones
                más destacadas. ¡Encuentra con Instabus el destino que más
                combine contigo!
              </p>
            </div>
          </div>

          <div className="columns mt-4">
            <div className="column is-6">
              <h4 className="subtitle is-4">Lima</h4>
              <div className="content">
                <figure className="image is-256x256" style={{ float: 'left' }}>
                  <img src={LimaJpg} alt="Lima" />
                </figure>
                <p>
                  ¡Que nadie te lo cuente, porque si no has conocido Lima, no
                  conoces nada!. Viaja ahora con Instabus al corazón del Perú y
                  descubre la capital llena de maravillas por revelar. Enamórate
                  de sus calles, disfruta de su diversidad de arquitectura
                  colonial y edificios modernos. Cómo dejar de mencionar su
                  riquísima gastronomía, en la que puedes encontrar la variedad
                  de platillos de la costa, sierra y selva. En resumen, Lima lo
                  tiene todo y es una ciudad que tiene que estar en primer lugar
                  en tus destinos de viajes.
                </p>
              </div>
            </div>

            <div className="column is-6">
              <h4 className="subtitle is-4">Huaral</h4>
              <div className="content">
                <figure className="image is-256x256" style={{ float: 'left' }}>
                  <img src={HuaralJpg} alt="Huaral" />
                </figure>
                <p>
                  ¿Sabías que Huaral es considerada como la capital de la
                  agricultura y también como el Machu Picchu limeño? Tienes dos
                  grandes razones para no dejar de visitar Huaral. Huaral tiene
                  muchos destinos turísticos y es considerada capital de la
                  agricultura por la gran variedad de productos agropecuarios
                  que cultivan en sus valles. También se encuentran las
                  deslumbrantes Ruinas de Rupac, un santuario preincaico. ¿Te
                  imaginas acampar con la vista del cielo nocturno? En Instabus
                  te acercamos a disfrutar de lo mejor del turismo en Lima y el
                  norte chico.
                </p>
              </div>
            </div>
          </div>

          <div className="columns mt-4">
            <div className="column is-6">
              <h4 className="subtitle is-4">Huacho</h4>

              <div className="content">
                <figure className="image is-256x256" style={{ float: 'left' }}>
                  <img src={HuachoJpg} alt="Huacho" />
                </figure>
                <p>
                  ¡A Huacho me fui!, como dice la famosa frase de una canción.
                  ¿Y por qué tienes que ir a Huacho?, porque es el destino ideal
                  para poder desconectarte un momento de la rutina diaria, pasar
                  unas vacaciones recorriendo sus playas y disfrutar de buenos
                  momentos en la “Capital de la hospitalidad”. Con Instabus
                  llegarás a este destino que se encuentra en la costa central
                  del Perú, la capital de la provincia de Huaura y sede regional
                  del Departamento de Lima.
                </p>
              </div>
            </div>

            <div className="column is-6">
              <h4 className="subtitle is-4">Supe</h4>
              <div className="content">
                <figure className="image is-256x256" style={{ float: 'left' }}>
                  <img src={SupeJpg} alt="Supe" />
                </figure>
                <p>
                  ¿Sabes por qué Supe es uno de los destinos que no puedes dejar
                  de visitar en tu ruta por el norte chico? Porque allí se
                  encuentra la ciudadela de Caral, la civilización más antigua
                  de América que recibe turistas de todos los países del mundo.
                  Instabus te lleva a Supe, la encantadora ciudad costera que se
                  encuentra a solo 4 horas de viaje, a un super precio para que
                  compres tu pasaje y no tengas excusas de iniciar esta
                  aventura.
                </p>
              </div>
            </div>
          </div>

          <div className="columns mt-4">
            <div className="column is-6">
              <h4 className="subtitle is-4">Barranca</h4>

              <div className="content">
                <figure className="image is-256x256" style={{ float: 'left' }}>
                  <img src={BarrancaJpg} alt="Barranca" />
                </figure>
                <p>
                  ¿Por qué visitar Barranca? Porque es una encantadora ciudad
                  costera ubicada a solo 4 horas de la ciudad de Lima. Con
                  Instabus podrás conocer este destino y disfrutar del sol y
                  broncearte en sus bellas playas de olas como Atarraya,
                  Carabela, Caleta Vidal, Chorrillos, El Amor, El Colorado,
                  Lampay, La Viuda y muchas más. Si estás buscando playas con
                  olas perfectas para natación suave, body surf o una buena
                  tabla de surf hawaiana, Barranca es el destino del norte chico
                  para ti.
                </p>
              </div>
            </div>

            <div className="column is-6">
              <h4 className="subtitle is-4">Paramonga</h4>
              <div className="content">
                <figure className="image is-256x256" style={{ float: 'left' }}>
                  <img src={ParamongaJpg} alt="Paramonga" />
                </figure>
                <p>
                  ¿Sabes por qué los mejores blogueros de viajes recomiendan a
                  la ciudad de Paramonga? Porque es una ciudad llena de
                  historia. Su máximo atractivo turístico es la fortaleza de
                  Paramonga, cuya edificación es una imponente pirámide
                  escalonada, hecha de adobe. Instabus te permite llegar a este
                  hermoso destino, el cual es uno de los cinco distritos de
                  Barranca, ubicados en la zona norte de Lima.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <InformationSection />
    </>
  );
};

export default Destinations;
