import React, { Component } from 'react';
import SearchFormSection from './SearchFormSection';
import DestinationSection from './DestinationSection';
import InformationSection from './InformationSection';
import ShowModalInHome from './ShowModalInHome';

class Home extends Component {
  render() {
    return (
      <>
        <SearchFormSection />
        <DestinationSection />
        <InformationSection />

        <ShowModalInHome active/>
      </>
    );
  }
}

export default Home;
