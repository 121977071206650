import React, { Fragment } from "react";
import ReduxToastr from "react-redux-toastr";
import { Router, Route } from "react-router-dom";
import history from "./history";
import Home from "./components/website/Home";
import Nav from "./components/website/Nav";
import Footer from "./components/website/Footer";
import Itinerary from "./components/website/booking/itinerary/Itinerary";
import {
  ABOUT_PATH,
  BUS_SERVICES_PATH,
  CONFIRMATION_STEP_PATH,
  AGENCY_PATH,
  DESTINATIONS_PATH,
  ERROR_STEP_PATH,
  HOME_PATH,
  ITINERARIES_STEP_PATH,
  PAGE_NOT_FOUND_PATH,
  PARCEL_SERVICES_PATH,
  PASSENGERS_STEP_PATH,
  RESULT_STEP_PATH,
  RETURN_ITINERARIES_STEP_PATH,
  TIMEOUT_STEP_PATH,
  TERMS_AND_CONDITIONS_PATH,
  PRIVACY_POLICIES_PATH,
  PAY_CONFIRMATION_STEP_PATH,
} from "./config/paths";
import Passengers from "./components/website/booking/passenger/Passengers";
import Result from "./components/website/booking/result/Result";
import Confirmation from "./components/website/booking/result/Confirmation";
import Error from "./components/website/booking/result/Error";
import PageNotFound from "./components/website/PageNotFound";

import "./resources/css/bulma.min.css";
import "./resources/css/app.css";
import Timeout from "./components/website/Timeout";
import About from "./components/website/About";
import BusServices from "./components/website/BusServices";
import ParcelServices from "./components/website/ParcelServices";
import Destinations from "./components/website/Destinations";
import Contact from "./components/website/Contact";
import TermsAndConditions from "./components/website/TermsAndConditions";
import PrivacyPolicies from "./components/website/PrivacyPolicies";
import Layer from "./Layer";
import PayConfirmation from "./components/website/booking/result/PayConfirmation";

const home = Layer(Home);
const about = Layer(About);
const busServices = Layer(BusServices);
const parcelServices = Layer(ParcelServices);
const destinations = Layer(Destinations);
const contact = Layer(Contact);
const termsAndConditions = Layer(TermsAndConditions);
const privacyPolicies = Layer(PrivacyPolicies);
const itinerary = Layer(Itinerary);
const passengers = Layer(Passengers);
const result = Layer(Result);
const confirmation = Layer(Confirmation);
const error = Layer(Error);
const timeout = Layer(Timeout);
const pageNotFound = Layer(PageNotFound);

const App = () => {
  return (
    <Fragment>
      <ReduxToastr transitionIn="fadeIn" transitionOut="fadeOut" />
      <Router history={history}>
        <Nav />

        <Route exact path={HOME_PATH} component={home} />
        <Route exact path={ABOUT_PATH} component={about} />
        <Route exact path={BUS_SERVICES_PATH} component={busServices} />
        <Route exact path={PARCEL_SERVICES_PATH} component={parcelServices} />
        <Route exact path={DESTINATIONS_PATH} component={destinations} />
        <Route exact path={AGENCY_PATH} component={contact} />
        <Route
          exact
          path={TERMS_AND_CONDITIONS_PATH}
          component={termsAndConditions}
        />
        <Route exact path={PRIVACY_POLICIES_PATH} component={privacyPolicies} />

        <Route path={`${ITINERARIES_STEP_PATH}/:query`} component={itinerary} />
        <Route
          path={`${RETURN_ITINERARIES_STEP_PATH}/:query`}
          component={itinerary}
        />
        <Route path={PASSENGERS_STEP_PATH} component={passengers} />
        <Route path={`${RESULT_STEP_PATH}/:id`} component={result} />
        <Route path={CONFIRMATION_STEP_PATH} component={confirmation} />
        <Route path={ERROR_STEP_PATH} component={error} />
        <Route path={PAY_CONFIRMATION_STEP_PATH} component={PayConfirmation} />
        <Route path={`${TIMEOUT_STEP_PATH}/:id`} component={timeout} />
        <Route path={PAGE_NOT_FOUND_PATH} component={pageNotFound} />
      </Router>
      <Footer />
    </Fragment>
  );
};

export default App;
