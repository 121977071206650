import Immutable from "immutable";
import {
  CHANGE_STEP,
  SEARCH_FOR_ITINERARIES,
  FLAG_SEARCHING_FOR_ITINERARIES,
  RESET_STEP,
  BUS_OCUPABILITY,
  FLAG_GET_SEATS,
  CLEAR_BUS_OCUPABILITY,
  SELECT_SEAT,
  FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS,
  DESELECT_SEAT,
  SELECT_ITINERARY,
  CLEAR_ITINERARIES,
  CLEAR_SEAT_SELECTIONS,
  SEAT_RESERVATION,
  CLEAR_SEAT_RESERVATION,
  PAYMENT_VISA_TOKEN,
  PAYMENT_VISA_SESSION,
  CLEAR_PAYMENT,
  GET_VISA_TRANSACTION,
} from "../actions/types";

const SEARCH_STEP = 0;

const INITIAL_STATE = new Immutable.Map({
  currentStep: SEARCH_STEP,
  search: new Immutable.Map({
    query: new Immutable.Map({
      source: null,
      sourceId: null,
      destination: null,
      destinationId: null,
      date: null,
      time: null,
      arrivalDate: null,
      arrivalTime: null,
      serviceType: null,
      returnDate: null,
      returnTime: null,
      returnArrivalDate: null,
      returnArrivalTime: null,
      returnServiceType: null,
      hasReturn: false,
    }),
  }),
  itineraries: new Immutable.Map({
    results: new Immutable.Set(),
    searchingForItineraries: false,
  }),
  seats: new Immutable.Map({
    results: new Immutable.Map(),
    getSeats: false,
    seatSelectionChangesInProgress: new Immutable.Set(),
    seatSelections: new Immutable.Set(),
    returnSeatSelections: new Immutable.Set(),
    seatReservation: new Immutable.Map(),
  }),
  payment: new Immutable.Map({
    visa: new Immutable.Map({
      token: null,
      session: new Immutable.Map(),
      transaction: new Immutable.Map(),
    }),
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case RESET_STEP:
      return state.set(
        payload.statePropertyName,
        INITIAL_STATE.get(payload.statePropertyName)
      );
    case CHANGE_STEP:
      return state.set("currentStep", payload);
    case SEARCH_FOR_ITINERARIES:
      if (!payload.hasReturn) {
        return state
          .setIn(
            ["search", "query"],
            new Immutable.Map({
              source: payload.source,
              sourceId: payload.sourceId,
              destination: payload.destination,
              destinationId: payload.destinationId,
              date: payload.date,
              returnDate: payload.returnDate,
              hasReturn: payload.hasReturn,
            })
          )
          .setIn(
            ["itineraries", "results"],
            new Immutable.Set(payload.results)
          );
      } else {
        return state
          .setIn(["search", "query", "hasReturn"], payload.hasReturn)
          .setIn(
            ["itineraries", "results"],
            new Immutable.Set(payload.results)
          );
      }
    case FLAG_SEARCHING_FOR_ITINERARIES:
      return state.setIn(["itineraries", "searchingForItineraries"], payload);
    case BUS_OCUPABILITY:
      return state.setIn(["seats", "results"], new Immutable.Map(payload));
    case FLAG_GET_SEATS:
      return state.setIn(["seats", "getSeats"], payload);
    case CLEAR_BUS_OCUPABILITY:
      return state.setIn(
        ["seats", "results"],
        INITIAL_STATE.getIn(["seats", "results"])
      );
    case CLEAR_SEAT_SELECTIONS:
      if (state.getIn(["search", "query", "hasReturn"])) {
        return state.setIn(
          ["seats", "returnSeatSelections"],
          INITIAL_STATE.getIn(["seats", "returnSeatSelections"])
        );
      } else {
        return state.setIn(
          ["seats", "seatSelections"],
          INITIAL_STATE.getIn(["seats", "seatSelections"])
        );
      }
    case SELECT_SEAT:
      if (state.getIn(["search", "query", "hasReturn"])) {
        return state.setIn(
          ["seats", "returnSeatSelections"],
          state.getIn(["seats", "returnSeatSelections"]).add(payload)
        );
      } else {
        return state.setIn(
          ["seats", "seatSelections"],
          state.getIn(["seats", "seatSelections"]).add(payload)
        );
      }
    case SELECT_ITINERARY:
      if (state.getIn(["search", "query", "hasReturn"])) {
        return state
          .setIn(["search", "query", "returnTime"], payload.time)
          .setIn(["search", "query", "returnServiceType"], payload.serviceType)
          .setIn(["search", "query", "returnArrivalDate"], payload.arrivalDate)
          .setIn(["search", "query", "returnArrivalTime"], payload.arrivalTime);
      } else {
        return state
          .setIn(["search", "query", "time"], payload.time)
          .setIn(["search", "query", "serviceType"], payload.serviceType)
          .setIn(["search", "query", "arrivalDate"], payload.arrivalDate)
          .setIn(["search", "query", "arrivalTime"], payload.arrivalTime);
      }
    case CLEAR_ITINERARIES:
      return state.setIn(["itineraries"], INITIAL_STATE.getIn(["itineraries"]));
    case FLAG_SEAT_SELECTION_CHANGE_IN_PROGRESS:
      if (payload.status === "start") {
        return state.setIn(
          ["seats", "seatSelectionChangesInProgress"],
          state
            .getIn(["seats", "seatSelectionChangesInProgress"])
            .add(payload.seatId)
        );
      }
      return state.setIn(
        ["seats", "seatSelectionChangesInProgress"],
        state
          .getIn(["seats", "seatSelectionChangesInProgress"])
          .remove(payload.seatId)
      );
    case DESELECT_SEAT:
      if (state.getIn(["search", "query", "hasReturn"])) {
        return state.setIn(
          ["seats", "returnSeatSelections"],
          state
            .getIn(["seats", "returnSeatSelections"])
            .delete(
              state
                .getIn(["seats", "returnSeatSelections"])
                .findKey((seatSelection) => seatSelection.seatId === payload)
            )
        );
      } else {
        return state.setIn(
          ["seats", "seatSelections"],
          state
            .getIn(["seats", "seatSelections"])
            .delete(
              state
                .getIn(["seats", "seatSelections"])
                .findKey((seatSelection) => seatSelection.seatId === payload)
            )
        );
      }
    case SEAT_RESERVATION:
      return state.setIn(
        ["seats", "seatReservation"],
        new Immutable.Map(payload)
      );
    case CLEAR_SEAT_RESERVATION:
      return state.setIn(
        ["seats", "seatReservation"],
        INITIAL_STATE.getIn(["seats", "seatReservation"])
      );
    case PAYMENT_VISA_TOKEN:
      return state.setIn(["payment", "visa", "token"], payload);
    case PAYMENT_VISA_SESSION:
      return state.setIn(
        ["payment", "visa", "session"],
        new Immutable.Map(payload)
      );
    case GET_VISA_TRANSACTION:
      return state.setIn(["payment", "visa", "transaction"], payload);
    case CLEAR_PAYMENT:
      return state.setIn(["payment"], INITIAL_STATE.getIn(["payment"]));
    default:
      return state;
  }
};
