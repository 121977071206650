import React from 'react';
import Container from './common/Container';
import Section from './common/Section';
import InformationSection from './InformationSection';
import SearchFormSection from './SearchFormSection';

import BusEconomicoJpg from '../../resources/images/Bus_Economico_660x288px.jpg';
import BusExpressJpg from '../../resources/images/Bus_Express_660x288px.jpg';

const BusServices = () => {
  return (
    <>
      <SearchFormSection />
      <Section cssStyle="section bg-white">
        <Container>
          <div className="columns">
            <div className="column is-12">
              <p className="title is-3 is-spaced">
                Viaja seguro, Viaja express con Instabus
              </p>
              <p className="title is-6">
                Sabemos que la rapidez y la seguridad son esenciales para tu
                viaje, por eso no recogemos a otros pasajeros en ruta y
                cumplimos todos los protocolos de seguridad.
              </p>
              <p className="pb-4">
                En Instabus pensamos en ti, por eso te ofrecemos estas opciones
                para que puedas elegir y disfrutar durante todo tu viaje.
                Haremos que llegues a tu destino en el menor tiempo posible a
                través de un viaje agradable y seguro.
              </p>
              <p className="pb-4">
                Cumplimos rigurosamente todos los protocolos de seguridad que el
                MINSA indica para asegurar que todas las superficies estén
                libres de COVID-19. En Instabus realizamos diariamente procesos
                de limpieza y desinfección en todos nuestros vehículos y
                ambientes de trabajo (mobiliario, herramientas, equipos, entre
                otros).{' '}
              </p>
              <p className="pb-4">
                Todos nuestros colaboradores cumplen con los protocolos de
                seguridad para prevenir el COVID-19 y se encuentran en constante
                evaluación médica.
              </p>

              <p className="title is-4 is-spaced">
                Protocolos de seguridad para viajeros
              </p>
              <p className="title is-6">
                Antes de ingresar a nuestras instalaciones
              </p>
              <p className="pb-4">
                En Instabus realizamos el control de temperatura corporal,
                desinfección de manos, uso correcto de la mascarilla y protector
                facial. Es importante recordar que no se permitirá el viaje a
                pasajeros con temperatura de 37.5°C o más. Es obligatorio
                guardar distancia social de mínimo de un metro cuando se realiza
                la compra de pasajes y durante la espera para abordar el bus.
                Los viajeros deberán llenar y entregar una declaración jurada de
                salud.{' '}
              </p>

              <p className="title is-6">Durante el viaje</p>

              <p>
                En Instabus todos nuestros buses cuentan con cobertores de
                polietileno de 16 milímetros entre asientos para proteger a los
                pasajeros, como lo indica el MINSA. También mantenemos el
                ambiente correctamente ventilado.
              </p>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="columns mt-4">
                <div className="column is-4">
                  <p className="title is-4 is-spaced">
                    Servicio Económico de Instabus
                  </p>
                  <p className="pb-4">
                    Te ofrecemos esta opción para que no dejes de viajar y
                    disfrutar de tus lugares favoritos. Nuestro servicio
                    económico es cómodo, seguro y a un precio al alcance de tu
                    bolsillo. Ahora no tienes excusas para conocer nuevos
                    destinos.
                  </p>

                  <p className="title is-6">Servicio disponible para rutas</p>
                  <p className="pb-4">
                    Lima – Chancay - Huacho – Supe - Barranca – Paramonga
                  </p>
                </div>
                <div className="column is-2">
                  <div className="content">
                    <p className="title is-6 is-spaced">Comodidades de viaje</p>
                    <div>
                      <ul className="is-size-7">
                        <li>Asientos con 140° de reclinación</li>
                        <li>Ambiente bioseguro</li>
                        <li>Cobertor de polietileno entre asientos</li>
                        <li>Velocidad controlada</li>
                        <li>Luz de lectura individual</li>
                        <li>Bus monitoreado por GPS</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <img
                    className="image-border"
                    src={BusEconomicoJpg}
                    alt="Bus Economico"
                  />
                </div>
              </div>
              <div className="columns mt-4">
                <div className="column is-4">
                  <p className="title is-4 is-spaced">
                    Servicio Express de Instabus
                  </p>
                  <p className="pb-4">
                    Si deseas llegar en menor tiempo a tu destino, esta opción
                    es para ti. En nuestro servicio express podrás viajar sin
                    escalas, de forma directa, de agencia a agencia. Con
                    Instabus podrás disfrutar del viaje, navegar en tus redes
                    sociales con nuestro servicio WiFi o relajarte con las
                    mejores películas que transmitimos durante el trayecto y así
                    viajes con la mayor comodidad que mereces.
                  </p>

                  <p className="title is-6">Servicio disponible para rutas</p>
                  <p className="pb-4">
                    Lima - Huacho – Supe - Barranca – Paramonga
                  </p>
                </div>
                <div className="column is-2">
                  <div className="content">
                    <p className="title is-6 is-spaced">Comodidades de viaje</p>
                    <div>
                      <ul className="is-size-7">
                        <li>Asientos con 140° de reclinación</li>
                        <li>Ambiente bioseguro</li>
                        <li>Cobertor de polietileno entre asientos</li>
                        <li>Velocidad controlada</li>
                        <li>Luz de lectura individual</li>
                        <li>Bus monitoreado por GPS</li>
                        <li>WiFi !Nuevo!</li>
                        <li>Puertos USB en cada asiento</li>
                        <li>Tv video a bordo</li>
                        <li>Música seleccionada</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <img
                    className="image-border"
                    src={BusExpressJpg}
                    alt="Bus Express"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <InformationSection />
    </>
  );
};

export default BusServices;
