import React, { Component, Fragment } from 'react';
import Moment from 'moment';
import { toLowerCase, toUpperFirst } from '../../../../utils/string';
import { formattedWithHourMinutes } from '../../../../utils/date';
import { FULL_TIME_FORMAT } from '../../../../config/locale';
import HomeButton from '../../../website/HomeButton';

import ExpressServiceJpg from '../../../../resources/images/Servicio-Express.jpg';
import EconomicServiceJpg from '../../../../resources/images/Servicio-Economico.jpg';

class ItineraryResult extends Component {
  render() {
    const { itineraries, handleSeatMapModal } = this.props;

    if (itineraries.length === 0) {
      return (
        <Fragment>
          <p>No hay itinerarios para la fecha seleccionada</p>
          <br />
          <HomeButton />
        </Fragment>
      );
    }

    return itineraries.map((itinerary, index) => {
      const departureTime = Moment(itinerary.departureTime, 'HH:mm:ss');
      const formattedDepartureTime = departureTime.format(FULL_TIME_FORMAT);

      const arrivalTime = Moment(itinerary.arrivalTime, 'HH:mm:ss');
      const formattedArrivalTime = arrivalTime.format(FULL_TIME_FORMAT);

      let serviceImage = EconomicServiceJpg;

      if (
        toLowerCase(itinerary.serviceType) === 'instabus' ||
        toLowerCase(itinerary.serviceType) === 'express'
      ) {
        serviceImage = ExpressServiceJpg;
      }

      return (
        <div className="result box" key={index}>
          <article className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img src={serviceImage} alt="Service" />
              </figure>
            </div>
            <div className="media-content">
              <div className="content">
                <div className="columns">
                  <div className="column">
                    <p>
                      <small>
                        Servicio {toUpperFirst(itinerary.serviceType)}
                      </small>
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <strong>{formattedDepartureTime}</strong>
                      <br />
                      <small>{toUpperFirst(itinerary.sourceLocation)}</small>
                    </p>
                  </div>
                  <div className="column">
                    <span className="icon is-small">
                      <i className="fas fa-clock" aria-hidden="true"></i>
                    </span>
                    <p>
                      <small>
                        {formattedWithHourMinutes(itinerary.duration)}
                      </small>
                      <br />
                    </p>
                  </div>
                  <div className="column">
                    <p>
                      <strong>{formattedArrivalTime}</strong>
                      <br />
                      <small>
                        {toUpperFirst(itinerary.destinationLocation)}
                      </small>
                    </p>
                  </div>
                  <div className="column">
                    <button
                      className="button is-primary"
                      onClick={() =>
                        handleSeatMapModal({
                          itineraryId: itinerary.id,
                          routeId: itinerary.routeId,
                          serviceType: toUpperFirst(itinerary.serviceType),
                          time: itinerary.departureTime,
                          arrivalDate: itinerary.arrivalDate,
                          arrivalTime: itinerary.arrivalTime,
                        })
                      }
                    >
                      <span>Seleccionar asiento</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      );
    });
  }
}

export default ItineraryResult;
