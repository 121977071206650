import React, { Component } from "react";
import QueryString from "query-string";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  CONFIRMATION_STEP_INDEX,
  generateSteps,
} from "../../../config/constants";

class BookingNavbar extends Component {
  renderSteps = () => {
    const {
      date,
      source,
      sourceId,
      destination,
      destinationId,
      returnDate,
      currentStep,
    } = this.props;
    const payload = {
      origin: `${sourceId}-${source}`,
      destination: `${destinationId}-${destination}`,
      date_start: date,
      date_end: returnDate,
    };
    const steps = generateSteps(QueryString.stringify(payload));
    return steps.map((step, index) => {
      if (index === currentStep) {
        return (
          <li key={index} className="steps-segment is-active">
            <span className="steps-marker">
              <span className="icon">
                <i className={`fa fa-${step.icon}`}></i>
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">{step.title}</p>
            </div>
          </li>
        );
      } else if (index > currentStep) {
        return (
          <li key={index} className="steps-segment">
            <span className="steps-marker">
              <span className="icon">
                <i className={`fa fa-${step.icon}`}></i>
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">{step.title}</p>
            </div>
          </li>
        );
      }
      if (currentStep === CONFIRMATION_STEP_INDEX) {
        return (
          <li key={index} className="steps-segment">
            <span className="steps-marker">
              <span className="icon">
                <i className={`fa fa-${step.icon}`}></i>
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">{step.title}</p>
            </div>
          </li>
        );
      } else {
        return (
          <li key={index} className="steps-segment">
            <Link to={step.path} className="has-text-dark">
              <span className="steps-marker">
                <span className="icon">
                  <i className={`fa fa-${step.icon}`}></i>
                </span>
              </span>
              <div className="steps-content">
                <p className="heading">{step.title}</p>
              </div>
            </Link>
          </li>
        );
      }
    });
  };

  render() {
    return (
      <ul className="steps is-centered has-content-centered is-horizontal">
        {this.renderSteps()}
      </ul>
    );
  }
}

const mapStateToProps = ({ Booking }) => ({
  source: Booking.getIn(["search", "query", "source"]),
  sourceId: Booking.getIn(["search", "query", "sourceId"]),
  destination: Booking.getIn(["search", "query", "destination"]),
  destinationId: Booking.getIn(["search", "query", "destinationId"]),
  date: Booking.getIn(["search", "query", "date"]),
  returnDate: Booking.getIn(["search", "query", "returnDate"]),
  currentStep: Booking.getIn(["currentStep"]),
});

export default connect(mapStateToProps)(BookingNavbar);
