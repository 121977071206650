const validateResponse = async (response) => {
  const OK_HTTP_STATUS_CODE = 200;

  if (response.status !== OK_HTTP_STATUS_CODE) {
    const error = await response.json();

    const errorObject = {
      statusCode: response.status,
      status: error.status || null,
      message: error.message || 'Error',
    };

    throw errorObject;
  }
};

export default validateResponse;
