import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import Section from "../../common/Section";
import ItineraryResultHeader from "./ItineraryResultHeader";
import Container from "../../common/Container";
import ItineraryFilter from "./ItineraryFilter";
import SelectedItineraryFilter from "./SelectedItineraryFilter";
import ItineraryOrder from "./ItineraryOrder";
import ItineraryResult from "./ItineraryResult";
import SeatSelection from "../seat/SeatSelection";
import {
  busOcupability,
  clearBusOcupability,
  searchForItineraries,
  selectItinerary,
  changeStep,
  clearItineraries,
  clearSeatSelections,
} from "../../../../actions";
import {
  ITINERARY_FILTER_OPTIONS,
  ITINERARIES_STEP_INDEX,
} from "../../../../config/constants";
import {
  HOME_PATH,
  PASSENGERS_STEP_PATH,
  RETURN_ITINERARIES_STEP_PATH,
} from "../../../../config/paths";
import Loader from "../../common/Loader";
import history from "../../../../history";

class Itinerary extends Component {
  state = {
    isActiveModal: "",
    serviceType: "",
    itineraryId: 0,
    routeId: 0,
    filteredItineraries: [],
    services: [],
    filters: [],
    orderBy: { field: "departureTimestamp", sort: "asc" },
    onMount: true,
    nextStepUri: PASSENGERS_STEP_PATH,
  };

  componentDidMount() {
    this.onMount();
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  getQueryParams = (query) => {
    const querySplited = query.split("&");

    const newQuery = {};

    querySplited.forEach((param) => {
      const [key, value] = param.split("=");
      newQuery[key] = value;
    });

    return newQuery;
  };

  onMount = () => {
    const {
      dispatchSearchForItineraries,
      dispatchChangeStep,
      dispatchClearItineraries,
      match: {
        params: { query },
      },
    } = this.props;

    let {
      date_end,
      date_start,
      destination,
      origin,
      status,
    } = this.getQueryParams(query);

    const [destinationId, destinationName] = destination.split("-");

    const [originId, originName] = origin.split("-");

    status = status ? status.substring(status.indexOf("=") + 1) : "";

    if (date_start === "" || destination === "" || origin === "") {
      history.push(HOME_PATH);
    } else {
      let hasReturn = false;

      if (date_end !== "") {
        if (status === "yes") {
          hasReturn = true;
        } else {
          this.setState({
            nextStepUri: `${RETURN_ITINERARIES_STEP_PATH}/${query}&status=yes`,
          });
        }
      }

      dispatchClearItineraries();
      dispatchSearchForItineraries({
        source: originName,
        sourceId: originId,
        destination: destinationName,
        destinationId: destinationId,
        date: date_start,
        returnDate: date_end,
        hasReturn,
      });
      dispatchChangeStep(ITINERARIES_STEP_INDEX);
    }
  };

  onDidUpdate = () => {
    const {
      itineraries,
      match: { path },
      date,
      time,
    } = this.props;

    const { filteredItineraries, services, onMount } = this.state;

    if (onMount && !itineraries.isEmpty()) {
      const newItineraries = [];
      const newServices = [];

      itineraries.forEach((itinerary) => {
        const newItinerary = { ...itinerary };

        newItinerary.departureTimestamp = Moment(
          `${itinerary.departureDate} ${itinerary.departureTime}`
        ).format("X");
        newItinerary.arrivalTimestamp = Moment(
          `${itinerary.arrivalDate} ${itinerary.arrivalTime}`
        ).format("X");

        if (itinerary.arrivalTime === "25:00:00")
          itinerary.arrivalTime = "23:59:59";
        if (itinerary.arrivalTime === "00:00:00")
          itinerary.arrivalTime = "23:59:59";

        const departureTime = Moment(itinerary.departureTime, "HH:mm:ss");
        const arrivalTime = Moment(itinerary.arrivalTime, "HH:mm:ss");
        newItinerary.duration = Moment(
          arrivalTime.diff(departureTime, "minutes")
        );

        // is return itineraries
        if (path.indexOf(RETURN_ITINERARIES_STEP_PATH) >= 0) {
          const departureTimestamp = Moment(`${date} ${time}`).format("X");
          if (departureTimestamp < newItinerary.departureTimestamp) {
            newItineraries.push(newItinerary);
          }
        } else {
          newItineraries.push(newItinerary);
        }

        // Create service type filter
        if (newServices.length === 0) newServices.push(itinerary.serviceType);

        if (
          _.findIndex(newItineraries, {
            serviceType: itinerary.serviceType,
          }) < 0
        ) {
          newServices.push(itinerary.serviceType);
        }
      });

      if (filteredItineraries.length === 0) {
        this.setState({
          filteredItineraries: this.orderBy(newItineraries),
        });
      }
      if (services.length === 0) {
        this.setState({ services: newServices });
      }
      this.setState({ onMount: false });
    }
  };

  handleSeatMapModal = ({
    itineraryId,
    routeId,
    serviceType,
    time,
    arrivalDate,
    arrivalTime,
  }) => {
    const {
      dispatchBusOcupability,
      dispatchClearBusOcupability,
      dispatchClearSeatSelections,
      dispatchSelectItinerary,
    } = this.props;

    dispatchClearBusOcupability();
    dispatchClearSeatSelections();
    dispatchSelectItinerary({
      time,
      serviceType,
      arrivalDate,
      arrivalTime,
    });
    dispatchBusOcupability({ itineraryId, routeId });
    if (this.state.isActiveModal === "") {
      this.setState({
        isActiveModal: "is-active",
        serviceType,
        itineraryId,
        routeId,
      });
    }
  };

  closeSeatMapModal = () => {
    if (this.state.isActiveModal !== "") {
      this.setState({
        isActiveModal: "",
        serviceType: "",
        itineraryId: 0,
        routeId: 0,
      });
    }
  };

  onFilterChange = (e) => {
    const {
      target,
      target: { checked, value },
    } = e;

    const { filters } = this.state;

    const oldFilters = [...filters];

    const filter = {
      value,
      label: target.getAttribute("label"),
      filterType: target.getAttribute("filtertype"),
    };

    if (checked) {
      oldFilters.push(filter);
      this.setState({ filters: this.orderBy(oldFilters, ["value"], ["asc"]) });
    } else {
      const newFilters = _.pullAllWith(oldFilters, [filter], _.isEqual);
      this.setState({ filters: this.orderBy(newFilters, ["value"], ["asc"]) });
    }
  };

  onItineraryOrderClick = (field, sort) => {
    let newSort = null;

    if (field !== this.state.orderBy.field) {
      newSort = "asc";
    } else if (sort === "asc") {
      newSort = "desc";
    } else if (sort === "desc") {
      newSort = "asc";
    }

    this.setState({ orderBy: { field, sort: newSort } });
  };

  orderBy = (collection, iteratees = [], order = []) => {
    const {
      orderBy: { field, sort },
    } = this.state;

    if (iteratees.length === 0) {
      iteratees = [field];
    }

    if (order.length === 0) {
      order = [sort];
    }

    return _.orderBy(collection, iteratees, order);
  };

  generatefilteredItineraries = () => {
    const { date } = this.props;
    const { filteredItineraries, filters } = this.state;

    let newFilteredItineraries = [];

    filters.forEach((filter) => {
      const value = filter.value.split("-");
      const [timeStart, timeEnd] = value;
      const timestampStart = Moment(`${date} ${timeStart}`).format("X");
      const timestampEnd = Moment(`${date} ${timeEnd}`).format("X");

      const property = `${filter.filterType}Timestamp`;

      filteredItineraries.forEach((itinerary) => {
        if (
          itinerary[property] >= timestampStart &&
          itinerary[property] <= timestampEnd &&
          _.findIndex(newFilteredItineraries, {
            id: itinerary.id,
          }) < 0
        ) {
          newFilteredItineraries.push(itinerary);
        }
      });
    });

    if (filters.length === 0) {
      newFilteredItineraries = [...filteredItineraries];
    }

    return this.orderBy(newFilteredItineraries);
  };

  render() {
    const { searchingForItineraries } = this.props;

    const {
      isActiveModal,
      serviceType,
      itineraryId,
      routeId,
      services,
      filters,
      orderBy: { field, sort },
      nextStepUri,
    } = this.state;

    if (searchingForItineraries) {
      return <Loader />;
    }

    const filteredItineraries = this.generatefilteredItineraries();
    
    return (
      <Section>
        <ItineraryResultHeader />
        <Container styles={{ marginTop: "1.5rem" }}>
          <div className="columns">
            <div className="column is-2 is-hidden-mobile">
              <ItineraryFilter
                departureTimeFilters={ITINERARY_FILTER_OPTIONS}
                arrivalTimeFilters={ITINERARY_FILTER_OPTIONS}
                serviceFilters={services}
                onChange={this.onFilterChange}
              />
            </div>
            <div className="column is-10">
              <SelectedItineraryFilter filters={filters} />
              <div className="columns">
                <div className="column">
                  <p>
                    <strong>{filteredItineraries.length} salidas</strong>{" "}
                    encontradas
                  </p>
                </div>
                <div className="column">
                  <ItineraryOrder
                    field={field}
                    sort={sort}
                    onClick={this.onItineraryOrderClick}
                  />
                </div>
              </div>
              {/* Itinerary list */}
              <ItineraryResult
                itineraries={filteredItineraries}
                handleSeatMapModal={this.handleSeatMapModal}
              />
              {/* Seatmap */}
              <SeatSelection
                isActive={isActiveModal}
                closeSeatMapModal={this.closeSeatMapModal}
                serviceType={serviceType}
                itineraryId={itineraryId}
                routeId={routeId}
                nextStepUri={nextStepUri}
              />
            </div>
          </div>
        </Container>
      </Section>
    );
  }
}

const mapStateToProps = ({ Booking }) => ({
  source: Booking.getIn(["search", "query", "source"]),
  destination: Booking.getIn(["search", "query", "destination"]),
  date: Booking.getIn(["search", "query", "date"]),
  time: Booking.getIn(["search", "query", "time"]),
  returnDate: Booking.getIn(["search", "query", "returnDate"]),
  itineraries: Booking.getIn(["itineraries", "results"]),
  searchingForItineraries: Booking.getIn([
    "itineraries",
    "searchingForItineraries",
  ]),
});

const mapDispatchToProps = {
  dispatchBusOcupability: busOcupability,
  dispatchClearBusOcupability: clearBusOcupability,
  dispatchSearchForItineraries: searchForItineraries,
  dispatchSelectItinerary: selectItinerary,
  dispatchChangeStep: changeStep,
  dispatchClearItineraries: clearItineraries,
  dispatchClearSeatSelections: clearSeatSelections,
};

export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);
