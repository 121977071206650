import React from "react";
import { HOME_PATH } from "../../config/paths";
import Section from "./common/Section";

const PageNotFound = () => {
  return (
    <Section cssStyle="hero is-fullheight has-text-centered">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">404</h1>
          <h2 className="subtitle">La página solicitada no se encontró</h2>
          <br />
          <div className="column has-text-centered">
            <a href={HOME_PATH} class="button is-primary">
              Volver al Inicio
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default PageNotFound;
