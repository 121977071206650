import React from 'react';
import Section from './common/Section';
import Container from './common/Container';
import InformationSectionColumn from './InformationSectionColumn';
import BusIcon from './../../resources/images/Bus_icon.png';
import CardIcon from './../../resources/images/Card_icon.png';
import CheckIcon from './../../resources/images/Check_icon.png';

const InformationSection = () => {
  return (
    <Section cssStyle="section information">
      <Container>
        <div className="columns is-centered">
          <div className="column has-text-centered">
            <p className="title is-4 is-spaced">
              Elige Instabus para tus viajes y crea experiencias estupendas
            </p>
            <p className="subtitle is-6 subtitle-home-section">
              ¿Aún no conoces el norte chico? ¿Qué estás esperando? En Instabus
              llevamos más de 20 años acompañando a nuestros viajeros en su
              visita por estas tierras cálidas. Te ofrecemos el mejor servicio
              de transporte seguro y rápido. ¿Ya alistaste maletas? Disfruta de
              los mejores destinos que solo se encuentran en el Perú.
            </p>
          </div>
        </div>
        <div className="columns is-centered">
          <InformationSectionColumn
            imageSrc={BusIcon}
            imageAlt=""
            title="Modernos buses"
            description="En Instabus contamos con una moderna flota de 50 buses que te brindarán comodidad y seguridad a lo largo de todo tu viaje."
          />
          <InformationSectionColumn
            imageSrc={CardIcon}
            imageAlt=""
            title="Diversos medios de pago"
            description="Seguimos innovando en Instabus. Ahora podrás pagar tu pasaje en nuestra web con tu tarjeta Visa crédito, débito,  American Express, y MasterCard."
          />
          <InformationSectionColumn
            imageSrc={CheckIcon}
            imageAlt=""
            title="Excelente infraestructura"
            description="Contamos con agencias propias en Paramonga, Barranca, Huacho y Lima. Cumplimos con todos los protocolos de seguridad para cuidarte."
          />
        </div>
      </Container>
    </Section>
  );
};

export default InformationSection;
