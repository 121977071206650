import { reducer as form } from 'redux-form';
import { reducer as toastr } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import City from './City';
import Booking from './Booking';
import Customer from './Customer';
import Business from './Business';

export default combineReducers({
  form,
  toastr,
  City,
  Booking,
  Customer,
  Business,
});
