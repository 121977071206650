import React from 'react';
import { Link } from 'react-router-dom';

import PasajeIcon from '../../resources/images/Pasaje_icon.png';
import EncomiendaIcon from '../../resources/images/Encomienda_icon.png';
import CargoIcon from '../../resources/images/Cargo_icon.png';

const Card = ({
  id,
  href,
  className,
  title,
  subtitle,
  address,
  timetable,
  availableServices,
}) => {
  const destinationIconsCss = title === 'Huaral' ? 'destination-icons' : null;

  const firstCss = title === 'Huaral' ? 'first' : null;

  let pasajeIconResource = null;

  if (availableServices.indexOf('Pasajes') >= 0) {
    pasajeIconResource = (
      <img className="is-rounded" src={PasajeIcon} alt="Pasaje" />
    );
  }

  let encomiendaIconResource = null;

  if (availableServices.indexOf('Encomienda') >= 0) {
    encomiendaIconResource = (
      <img
        className={`is-rounded ${firstCss}`}
        src={EncomiendaIcon}
        alt="Encomienda"
      />
    );
  }

  let cargoIconResource = null;

  if (availableServices.indexOf('Cargo') >= 0) {
    cargoIconResource = (
      <img className="is-rounded" src={CargoIcon} alt="Cargo" />
    );
  }

  const availableServiceIcons = (
    <div className={destinationIconsCss}>
      {pasajeIconResource}
      {encomiendaIconResource}
      {cargoIconResource}
    </div>
  );

  return (
    <div className="column product" id={id}>
      <Link to={href}>
        <div className={`destination ${className}`}>
          <div className="destination-info">
            <span className="has-text-weight-black destination-text ib-primary destination-title">
              {title}
            </span>
            <br />
            <span className="has-text-weight-light destination-text ib-primary destination-subtitle">
              {subtitle}
            </span>
            <br />
            <br />
            <span className="has-text-weight-light destination-text destination-content">
              {address}
            </span>
            <br />
            <span className="has-text-weight-light destination-text destination-content">
              {timetable}
            </span>
            <br />
            <span className="has-text-weight-bold destination-text destination-content">
              Servicios disponibles
            </span>
            <br />
            <span className="has-text-weight-light destination-text destination-content">
              {availableServices}
            </span>
            {availableServiceIcons}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
