import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import SeatMap from "./SeatMap";
import { selectSeat, deselectSeat } from "../../../../actions/Booking";
import history from "../../../../history";

class SeatSelection extends Component {
  handleNextButtonClick = () => {
    history.push(this.props.nextStepUri);
  };

  renderSeats() {
    const { hasReturn, seatSelections, returnSeatSelections } = this.props;

    let selectedSeats = null;
    if (hasReturn) {
      selectedSeats = returnSeatSelections.map(
        (seatReservation) => seatReservation.seatNumber
      );
    } else {
      selectedSeats = seatSelections.map(
        (seatReservation) => seatReservation.seatNumber
      );
    }

    if (!selectedSeats.size) {
      return "-";
    }

    return selectedSeats.map((selectedSeat) => (
      <span
        className="tag is-dark"
        style={{ marginRight: "1px", marginLeft: "1px" }}
        key={selectedSeat}
      >
        {selectedSeat}
      </span>
    ));
  }

  renderTotalPrice() {
    const { hasReturn, seatSelections, returnSeatSelections } = this.props;

    let seats = null;
    if (hasReturn) {
      seats = returnSeatSelections.map((seatReservation) => seatReservation);
    } else {
      seats = seatSelections.map((seatReservation) => seatReservation);
    }

    let total = 0;
    if (!seats.size) {
      return total.toFixed(2);
    }
    seats.forEach((seat) => {
      total += parseFloat(seat.price);
    });

    return total.toFixed(2);
  }

  render() {
    const {
      isActive,
      closeSeatMapModal,
      serviceType,
      seats,
      loading,
      seatSelections,
      returnSeatSelections,
      seatSelectionChangesInProgress,
      itineraryId,
      routeId,
      dispatchSelectSeat,
      dispatchDeselectSeat,
      hasReturn,
    } = this.props;
    let content = null;
    let closeButton = null;
    if (loading && seats.isEmpty()) {
      content = (
        <Fragment>
          <div className="columns is-mobile is-centered">
            <i className="fa fa-4x fa-spinner fa-spin" />
          </div>
        </Fragment>
      );
    } else {
      closeButton = (
        <button
          className="modal-close is-large"
          onClick={closeSeatMapModal}
          aria-label="close"
        ></button>
      );

      const floorList = seats.get("floorList") || [];

      content = (
        <Fragment>
          <p className="title is-5 has-text-centered">
            Elige un asiento disponible para continuar con tu reserva
          </p>

          <div className="columns">
            <div className="column is-8">
              <div id="seats-map">
                <SeatMap
                  floors={floorList}
                  hasReturn={hasReturn}
                  seatSelections={seatSelections}
                  returnSeatSelections={returnSeatSelections}
                  seatSelectionChangesInProgress={
                    seatSelectionChangesInProgress
                  }
                  itineraryId={itineraryId}
                  routeId={routeId}
                  handleSeatSelection={dispatchSelectSeat}
                  handleSeatDeselection={dispatchDeselectSeat}
                />
              </div>
            </div>
            {/* <!--Seleccionados--> */}
            <div className="column is-4">
              <div className="content">
                <p className="title is-6 has-text-centered">Tu selección</p>
                <div className="columns is-multiline is-mobile">
                  <div className="column is-full is-detail">
                    <small>N° de asientos:</small>
                    <br />
                    <small>{this.renderSeats()}</small>
                  </div>
                  <div className="column is-half is-detail">
                    <small>Servicio:</small>
                  </div>
                  <div className="column is-half is-detail has-text-right">
                    <small>{serviceType}</small>
                  </div>
                  <div className="column is-half is-detail">
                    <small>Total a pagar:</small>
                  </div>
                  <div className="column is-half is-detail has-text-right">
                    <p className="title is-6">
                      <small>S/&nbsp;</small>
                      <strong>{this.renderTotalPrice()}</strong>
                    </p>
                  </div>
                </div>
                <div className="buttons is-centered">
                  <button
                    className="button is-primary"
                    onClick={this.handleNextButtonClick}
                  >
                    <span>Continuar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <div className={`modal ${isActive}`} id="modal">
        <div className="modal-background"></div>
        <div className="modal-content modal-itinerary-content">
          <div className="box seats-map-box">{content}</div>
        </div>
        {closeButton}
      </div>
    );
  }
}

const mapStateToProps = ({ Booking }) => ({
  seats: Booking.getIn(["seats", "results"]),
  loading: Booking.getIn(["seats", "getSeats"]),
  seatSelections: Booking.getIn(["seats", "seatSelections"]),
  seatSelectionChangesInProgress: Booking.getIn([
    "seats",
    "seatSelectionChangesInProgress",
  ]),
  returnSeatSelections: Booking.getIn(["seats", "returnSeatSelections"]),
  hasReturn: Booking.getIn(["search", "query", "hasReturn"]),
});

const mapDispatchToProps = {
  dispatchSelectSeat: selectSeat,
  dispatchDeselectSeat: deselectSeat,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeatSelection);
