const formattedWithHourMinutes = (minutesTotals) => {
  let time = "-";
  if (minutesTotals > 0) {
    const hours = Math.floor(minutesTotals / 60);
    const minutes = minutesTotals % 60;
    time = `${hours > 0 ? `${hours}h` : "0h"} ${
      minutes > 0 ? `${minutes}m` : "0m"
    }`;
  }
  return time;
};

export { formattedWithHourMinutes };
