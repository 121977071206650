import React, { Component } from 'react';
import Card from './Card';
import Section from './common/Section';
import Container from './common/Container';

import LeftArrow from '../../resources/images/left-arrow.png';
import RightArrow from '../../resources/images/right-arrow.png';
import { CAROUSEL_DESTINATIONS_LIST } from '../../config/constants';

import $ from 'jquery';

class DestinationSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      numImages: 6,
      isMobile: window.matchMedia('only screen and (max-width: 760px)').matches,
    };
  }

  onClickLeftArrow = () => {
    const { isMobile, current, numImages } = this.state;

    let newCurrent = current;

    if (isMobile) {
      if (current > 0) {
        newCurrent = current - 1;
      } else {
        newCurrent = numImages - 1;
      }
    } else {
      if (current > 0) {
        newCurrent = current - 1;
      } else {
        newCurrent = numImages - 3;
      }
    }

    $('.carrusel').animate(
      { left: -$('#product_' + newCurrent).position().left },
      600
    );

    this.setState({ current: newCurrent });

    return false;
  };

  onClickRightArrow = () => {
    const { isMobile, current, numImages } = this.state;

    let newCurrent = current;

    if (isMobile) {
      if (numImages > current + 1) {
        newCurrent = current + 1;
      } else {
        newCurrent = 0;
      }
    } else {
      if (numImages - 2 > current + 1) {
        newCurrent = current + 1;
      } else {
        newCurrent = 0;
      }
    }

    $('.carrusel').animate(
      { left: -$('#product_' + newCurrent).position().left },
      600
    );

    this.setState({ current: newCurrent });

    return false;
  };

  render() {
    const carousel = CAROUSEL_DESTINATIONS_LIST.map((destination) => (
      <Card
        key={destination.id}
        id={destination.id}
        href={destination.agenciesPath}
        className={destination.className}
        title={destination.title}
        subtitle={destination.subtitle}
        address={destination.address}
        timetable={destination.timetable}
        availableServices={destination.availableServices}
      />
    ));

    return (
      <Section cssStyle="section bg-white">
        <Container>
          <div className="columns is-centered">
            <div className="column has-text-centered">
              <p className="title is-4 is-spaced">
                Destinos hacia el norte chico
              </p>
              <p className="subtitle is-6 subtitle-home-section">
                Deja de escuchar historias de los viajes de tus amigos. Alista
                tu mochila, compra tus pasajes en Instabus y comienza a tener
                experiencias por todos los sitios asombrosos que el norte chico
                tiene esperando por ti. Conoce tus nuevos lugares favoritos con
                Instabus: Lima, Huaral, Huacho, Supe, Barranca y Paramonga. ¡No
                esperes más y prepárate para la aventura!
              </p>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-10">
              <div className="columns is-multiline is-mobile is-centered is-mobile">
                <div id="carrusel">
                  <div onClick={this.onClickLeftArrow} className="left-arrow">
                    <img src={LeftArrow} alt="Flecha izquierda" />
                  </div>
                  <div onClick={this.onClickRightArrow} className="right-arrow">
                    <img src={RightArrow} alt="Flecha derecha" />
                  </div>
                  <div className="carrusel">{carousel}</div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    );
  }
}

export default DestinationSection;
