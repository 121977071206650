import Immutable from 'immutable';
import {
  FLAG_GETTING_BUSINESS,
  GET_BUSINESS,
  CLEAR_BUSINESS,
} from '../actions/types';

const INITIAL_STATE = Immutable.Map({
  current: Immutable.Map({
    content: Immutable.Map(),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_BUSINESS:
      return state.setIn(['current', 'loading'], payload);
    case GET_BUSINESS:
      return state.setIn(['current', 'content'], payload);
    case CLEAR_BUSINESS:
      return state.setIn(
        ['current', 'content'],
        INITIAL_STATE.getIn(['current', 'content'])
      );
    default:
      return state;
  }
};
