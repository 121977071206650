import React, { Fragment, Component } from 'react';
import {
  ABOUT_PATH,
  AGENCY_PATH,
  BUS_SERVICES_PATH,
  CONFIRMATION_STEP_PATH,
  DESTINATIONS_PATH,
  ITINERARIES_STEP_PATH,
  PARCEL_SERVICES_PATH,
  PASSENGERS_STEP_PATH,
  PRIVACY_POLICIES_PATH,
  TERMS_AND_CONDITIONS_PATH,
} from './config/paths';

const Layer = (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      const {
        location: { pathname },
      } = this.props;

      let title =
        'Transportes Instabus | Transportes Instabus | Lima - Huaral - Chancay - Huacho - Supe - Barranca - Paramonga';
      let description =
        'Encuentra en transportes Instabus todas las comidades para viajar a Lima, Huaral, Chancay, Huacho, Supe, Barranca y Paramonga.';

      switch (pathname) {
        case DESTINATIONS_PATH:
          title =
            'Destinos turísticos	| Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima - Huaral - Huacho - Supe - Barranca - Paramonga';
          description =
            'Disfruta de los mejores destinos y rutas del norte chico con transportes Instabus. Disfruta de la buena atención y comodidad con Instabus.';
          break;

        case BUS_SERVICES_PATH:
          title =
            'Servicio de Bus | Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima – Huaral - Huacho – Supe - Barranca – Paramonga - Cañete - Chincha - Pisco - Ica';
          description =
            'Viaja seguro, viaja express con instabus a Lima, Huaral, Chancay, Huacho, Supe, Barranca, Paramonga, Cañete, Chincha, Pisco, Ica.';
          break;

        case PARCEL_SERVICES_PATH:
          title =
            'Encomiendas | Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima – Huaral - Huacho – Supe - Barranca – Paramonga - Cañete - Chincha - Pisco - Ica';
          description =
            'Encuentra en transportes Instabus los servicios economico y express para realizar tus encomiendas desde la comodida de tu casa.';
          break;

        case AGENCY_PATH:
          title =
            'Contacto	| Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima - Huaral - Huacho - Supe - Barranca - Paramonga';
          description =
            'Encuentra en transporte Instabus telefonos de contacto, redes sociales y más para coordinar tu encomienda o comprar tu pasaje a uno de los mejores destinos del norte chico.';
          break;

        case ABOUT_PATH:
          title =
            'Nosotros | Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima - Huaral - Huacho - Supe - Barranca - Paramonga';
          description =
            "Encuentra en transporte instabus su historia, misión, visión, sus servicios. 'VIAJA SEGURO, VIAJA EXPRESS' con Instabus.";
          break;

        case CONFIRMATION_STEP_PATH:
          title =
            'Confirmación de Compra | Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima - Huaral - Huacho - Supe - Barranca - Paramonga';
          break;

        case PASSENGERS_STEP_PATH:
          title =
            'Información de pasajeros | Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima - Huaral - Huacho - Supe - Barranca - Paramonga';
          break;

        case PRIVACY_POLICIES_PATH:
          title =
            'Políticas de privacidad | Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima - Huaral - Huacho - Supe - Barranca - Paramonga';
          break;

        case ITINERARIES_STEP_PATH:
          title =
            'Compra de pasajes | Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima - Huaral - Huacho - Supe - Barranca - Paramonga';
          break;

        case TERMS_AND_CONDITIONS_PATH:
          title =
            'Términos y condiciones | Transportes Instabus | Transporte de Pasajeros y Encomiendas | Lima - Huaral - Huacho - Supe - Barranca - Paramonga';
          break;

        default:
          break;
      }

      document.title = title;
      document.getElementsByTagName('meta')[3].content = description;

      const scriptId = 'cs';

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = scriptId;
      script.async = true;
      script.innerHTML =
        "dataLayer.push({event:'key.event',eventCategory:'pantallas',eventAction:'view',eventLabel:'',url:'" +
        pathname +
        "'});";

      if (document.getElementById(scriptId)) {
        document.body.removeChild(document.getElementById(scriptId));
      }

      document.body.appendChild(script);
    }

    render() {
      return (
        <Fragment>
          <ChildComponent {...this.props} />
        </Fragment>
      );
    }
  }

  return ComposedComponent;
};

export default Layer;
