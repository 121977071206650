const DEFAULT_POST_CONFIG = {
  method: "POST",
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const DEFAULT_PUT_CONFIG = {
  method: "PUT",
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const DEFAULT_GET_CONFIG = {
  method: "GET",
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export { DEFAULT_POST_CONFIG, DEFAULT_PUT_CONFIG, DEFAULT_GET_CONFIG };
