import React from 'react';
import Container from './common/Container';
import Section from './common/Section';
import InformationSection from '../website/InformationSection';

const TermsAndConditions = () => {
  return (
    <>
      <Section cssStyle="section bg-white">
        <Container>
          <h1 className="title has-text-centered">Términos y Condiciones</h1>

          <div class="columns mt-4 is-centered has-text-justified">
            <div class="column is-10">
              <div class="content justify-content">
                <ol>
                  <li>
                    El boleto de viaje es personal e intransferible; válido para
                    la fecha y hora de viaje.
                  </li>
                  <li>
                    El pasajero debe presentarse 30 minutos antes de la hora de
                    viaje, debiendo presentar su boleto de viaje o impresión del
                    boleto electrónico y DNI para embarcarse.
                  </li>
                  <li>
                    Los menores de edad que viajen solos o en compañía de un
                    adulto que no sean sus padres deben presentar Autorización
                    Notarial firmada por sus padres.
                  </li>
                  <li>
                    Los niños mayores de 5 años pagan su pasaje completo y
                    viajan con DNI.
                  </li>
                  <li>
                    El pasajero tiene derecho a transportar hasta 20 kilos de
                    equipaje, exclusivamente maletas, maletines y bolsos con
                    artículos de uso personal.
                  </li>
                  <li>
                    La empresa no se responsabiliza por la perdida de equipajes
                    acompañados que contengan objetos de valor que no hubieran
                    sido previamente declarados a solicitud del pasajero.
                  </li>
                  <li>
                    El pasajero viaja amparado por el Seguro Obligatorio de
                    Accidentes de Tránsito (SOAT); de la compañía de seguros
                    "MAPFRE".
                  </li>
                  <li>
                    Al recibir este documento; El pasajero declara estar
                    conforme con las condiciones de transporte y servicio antes
                    indicado; ubicados en el counter de ventas detallado en el
                    letrero o banner y/o papel; sin derecho de reclamo alguno.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <InformationSection />
    </>
  );
};

export default TermsAndConditions;
