export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:8080';
export const HOST = process.env.REACT_APP_HOST || 'http://localhost:3000';
export const BASE_SECURE_ENDPOINT = '/secure/api';
export const BASE_SECURE_URL = BASE_URL + BASE_SECURE_ENDPOINT;

export const CITY_ENDPOINT = `${BASE_SECURE_URL}/city`;
export const ITINERARY_ENDPOINT = `${BASE_SECURE_URL}/itinerary`;
export const BUS_OCUPABILITY_ENDPOINT = `${BASE_SECURE_URL}/bus-occupancy`;
export const CUSTOMER_ENDPOINT = `${BASE_SECURE_URL}/customer`;
export const BUSINESS_ENDPOINT = `${BASE_SECURE_URL}/business`;
export const SEAT_RESERVATION_ENDPOINT = `${BASE_SECURE_URL}/seat/reserve`;
export const generateSeatReservationRevertEndpoint = (reservationId) => {
  return `${BASE_SECURE_URL}/reservation/${reservationId}/revert`;
};
export const VISA_AUTHORIZATION_ENDPOINT = `${BASE_SECURE_URL}/visa-transaction`;
export const GET_SESSION_ENDPOINT = `${BASE_SECURE_URL}/visa-transaction/get-session`;
