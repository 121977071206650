import React from 'react';
import Section from './common/Section';

const PageTitle = ({ title, subtitle }) => {
  return (
    <Section cssStyle="hero is-light is-bold">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
        </div>
      </div>
    </Section>
  );
};

export default PageTitle;
