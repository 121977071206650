import React, { Component, Fragment, forwardRef, createRef } from 'react';
import QueryString from 'query-string';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import DatePicker, { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale';
import Moment from 'moment-timezone';
import Section from './common/Section';
import Container from './common/Container';
import { getCities, clearCities } from '../../actions';
import history from '../../history';
import { ITINERARIES_STEP_PATH } from '../../config/paths';

import '../../../node_modules/react-datepicker/dist/react-datepicker.min.css';
import { TIME_ZONE, DATE_EN_FORMAT } from '../../config/locale';

registerLocale('es', es);

export class SearchFormSection extends Component {
  state = {
    source: '',
    destination: '',
    date: '',
    returnDate: '',
  };

  componentDidMount() {
    const { dispatchGetCities } = this.props;
    dispatchGetCities();
  }

  componentWillUnmount() {
    const { dispatchClearCities } = this.props;
    dispatchClearCities();
  }

  onHandleSearch = (e) => {
    const { source, destination, date, returnDate } = this.state;
    if (source === '') {
      toastr.error('Error', 'El Origen es obligatorio');
      return false;
    }
    if (destination === '') {
      toastr.error('Error', 'El Destino es obligatorio');
      return false;
    }
    if (date === '') {
      toastr.error('Error', 'La Fecha es obligatoria');
      return false;
    }
    const payload = {
      origin: source,
      destination,
      date_start: Moment.utc(date).clone().tz(TIME_ZONE).format(DATE_EN_FORMAT),
      date_end: returnDate
        ? Moment.utc(returnDate).clone().tz(TIME_ZONE).format(DATE_EN_FORMAT)
        : '',
    };
    history.push(`${ITINERARIES_STEP_PATH}/${QueryString.stringify(payload)}`);
  };

  renderSelect = (func) => {
    const { cityOptions } = this.props;
    const options = cityOptions.isEmpty() ? (
      <option value>Cargando...</option>
    ) : (
      cityOptions.map((option, index) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      })
    );

    return (
      <Fragment>
        <div className="select is-fullwidth">
          <div className="is-expanded">
            <select onChange={func}>
              {!cityOptions.isEmpty() ? (
                <option value="">Seleccione</option>
              ) : null}
              {options}
            </select>
          </div>
        </div>
        <div className="icon is-small is-left">
          <i className="fas fa-map-marker-alt"></i>
        </div>
      </Fragment>
    );
  };

  render() {
    const { date, returnDate } = this.state;

    const InputDatePicker = forwardRef(
      ({ value, onClick, props: { placeholder } }, ref) => (
        <input
          className="input"
          name="date"
          placeholder={placeholder}
          onClick={onClick}
          value={value}
          autoComplete="off"
          readOnly
        />
      )
    );

    const ref = createRef();

    return (
      <Section cssStyle="hero is-medium instabus-hero">
        <div className="hero-body">
          <Container>
            <h1 className="title">&nbsp;</h1>

            <div className="columns is-gapless">
              <div className="column is-10">
                <Section cssStyle="search-form">
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <div className="control has-icons-left">
                          {this.renderSelect((e) =>
                            this.setState({ source: e.target.value })
                          )}
                        </div>
                      </div>
                      <div className="field">
                        <div className="control is-expanded has-icons-left">
                          {this.renderSelect((e) =>
                            this.setState({ destination: e.target.value })
                          )}
                        </div>
                      </div>
                      <div className="field">
                        <div className="control is-expanded has-icons-left has-icons-right">
                          <DatePicker
                            selected={date}
                            locale="es"
                            onChange={(date) =>
                              this.setState({ date, returnDate: '' })
                            }
                            customInput={
                              <InputDatePicker
                                ref={ref}
                                props={{ placeholder: 'Fecha de ida' }}
                              />
                            }
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-calendar-alt"></i>
                          </span>
                        </div>
                      </div>
                      <div className="field">
                        <div className="control is-expanded has-icons-left has-icons-right">
                          <DatePicker
                            selected={returnDate}
                            locale="es"
                            onChange={(date) =>
                              this.setState({ returnDate: date })
                            }
                            customInput={
                              <InputDatePicker
                                ref={ref}
                                props={{ placeholder: 'Fecha de vuelta' }}
                              />
                            }
                            dateFormat="dd/MM/yyyy"
                            minDate={date}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-calendar-alt"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Section>
              </div>
              <div className="column is-2">
                <button
                  className="button is-fullwidth search-button is-primary"
                  onClick={this.onHandleSearch}
                >
                  <span className="icon">
                    <i className="fas fa-search"></i>
                  </span>
                  <span>Buscar</span>
                </button>
              </div>
            </div>
          </Container>
        </div>
      </Section>
    );
  }
}

const mapStateToProps = ({ City }) => ({
  cityOptions: City.getIn(['all', 'content']).map((city) => ({
    value: `${city.id}-${city.name}`,
    label: city.name,
  })),
});

const mapDispatchToProps = {
  dispatchGetCities: getCities,
  dispatchClearCities: clearCities,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFormSection);
