import React, { Component } from 'react';
import InstabusPng from '../../resources/images/instabus.png';
import Container from './common/Container';
import {
  // ABOUT_PATH,
  BUS_SERVICES_PATH,
  AGENCY_PATH,
  DESTINATIONS_PATH,
  HOME_PATH,
  PARCEL_SERVICES_PATH,
} from '../../config/paths';
import { Link } from 'react-router-dom';
import history from '../../history';

class Nav extends Component {
  state = {
    isActive: false,
  };

  onClickLink = (path) => {
    this.setState({ isActive: false });
    history.push(path);
  };

  render() {
    const { isActive } = this.state;
    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <Container>
          <div className="navbar-brand">
            <Link
              className="navbar-item"
              onClick={() => this.onClickLink(HOME_PATH)}
              to="#"
            >
              <img src={InstabusPng} alt="Instabus" />
            </Link>

            <div
              role="button"
              className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
              aria-label="menu"
              aria-expanded="false"
              onClick={() => this.setState({ isActive: !isActive })}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
            <div className="navbar-start"></div>

            <div className="navbar-end">
              <Link
                onClick={() => this.onClickLink(DESTINATIONS_PATH)}
                to="#"
                className="navbar-item"
              >
                Destinos y Rutas
              </Link>

              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link">Servicios</div>

                <div className="navbar-dropdown">
                  <Link
                    onClick={() => this.onClickLink(BUS_SERVICES_PATH)}
                    to="#"
                    className="navbar-item"
                  >
                    Bus
                  </Link>
                  <Link
                    onClick={() => this.onClickLink(PARCEL_SERVICES_PATH)}
                    to="#"
                    className="navbar-item"
                  >
                    Encomiendas
                  </Link>
                </div>
              </div>

              <Link
                onClick={() => this.onClickLink(AGENCY_PATH)}
                to="#"
                className="navbar-item"
              >
                Agencias
              </Link>
            </div>
          </div>
        </Container>
      </nav>
    );
  }
}

export default Nav;
