import { toastr } from 'react-redux-toastr';
import QueryString from 'query-string';
import { CUSTOMER_ENDPOINT } from '../config/endpoints';
import { CLEAR_CUSTOMER, GET_CUSTOMER, FLAG_GETTING_CUSTOMER } from './types';
import { DEFAULT_GET_CONFIG, DEFAULT_POST_CONFIG } from '../config/rest';
import validateResponse from '../utils/response-handler';

const flagGettingCustomer = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_CUSTOMER,
    payload: flag,
  });

const obtainCustomer = async ({ documentTypeId, documentNumber }) => {
  const payload = {
    documentTypeId,
    documentNumber,
  };

  const url = `${CUSTOMER_ENDPOINT}?${QueryString.stringify(payload)}`;

  const response = await fetch(url, DEFAULT_GET_CONFIG);

  await validateResponse(response);

  const customer = await response.json();

  return customer;
};

const getCustomer = ({ documentTypeId, documentNumber }) => async (
  dispatch
) => {
  try {
    dispatch(flagGettingCustomer(true));

    const customer = await obtainCustomer({ documentTypeId, documentNumber });

    dispatch({
      type: GET_CUSTOMER,
      payload: customer,
    });
  } catch (error) {
    toastr.error(error.message);
  } finally {
    dispatch(flagGettingCustomer(false));
  }
};

const createCustomer = async ({
  customerId,
  documentTypeId,
  documentNumber,
  name,
  lastName,
  motherLastName,
  gender,
  email,
  dob,
  mobilePhone,
}) => {
  try {
    const payload = {
      customerId,
      documentTypeId,
      documentNumber,
      name,
      lastName,
      motherLastName,
      gender,
      email,
      dob,
      mobilePhone,
    };

    const response = await fetch(CUSTOMER_ENDPOINT, {
      ...DEFAULT_POST_CONFIG,

      body: JSON.stringify(payload),
    });

    const customer = await response.json();

    return customer;
  } catch (error) {
    return null;
  }
};

const clearCustomer = () => (dispatch) =>
  dispatch({
    type: CLEAR_CUSTOMER,
  });

export { getCustomer, clearCustomer, obtainCustomer, createCustomer };
