import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { HOME_PATH } from '../../config/paths';
import Loader from './common/Loader';
import { seatReservationRevert } from '../../actions';

class Timeout extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      dispatchSeatReservationRevert,
    } = this.props;

    const response = dispatchSeatReservationRevert({ reservationId: id });

    if (response) {
      history.push(HOME_PATH);
    }
  }

  render() {
    return <Loader />;
  }
}

const mapDispatchToProps = {
  dispatchSeatReservationRevert: seatReservationRevert,
};

export default connect(null, mapDispatchToProps)(Timeout);
