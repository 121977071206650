import Immutable from "immutable";
import {
  FLAG_GETTING_CITIES,
  GET_CITIES,
  CLEAR_CITIES,
} from "../actions/types";

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Set([]),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_GETTING_CITIES:
      return state.setIn(["all", "loading"], payload);
    case GET_CITIES:
      return state.setIn(["all", "content"], Immutable.Set(payload));
    case CLEAR_CITIES:
      return state.setIn(
        ["all", "content"],
        INITIAL_STATE.getIn(["all", "content"])
      );
    default:
      return state;
  }
};
