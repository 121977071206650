import React from "react";
import Floor from "./Floor";

const Floors = ({
  floors,
  hasReturn,
  seatSelections,
  returnSeatSelections,
  seatSelectionChangesInProgress,
  itineraryId,
  routeId,
  handleSeatSelection,
  handleSeatDeselection,
}) =>
  floors.map((floor) => (
    <div key={floor.floorNumber}>
      <Floor
        floor={floor}
        hasReturn={hasReturn}
        seatSelections={seatSelections}
        returnSeatSelections={returnSeatSelections}
        seatSelectionChangesInProgress={seatSelectionChangesInProgress}
        itineraryId={itineraryId}
        routeId={routeId}
        handleSeatSelection={handleSeatSelection}
        handleSeatDeselection={handleSeatDeselection}
      />
    </div>
  ));

export default Floors;
