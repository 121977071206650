import React from "react";
import { HOME_PATH } from "../../config/paths";

const HomeButton = () => (
  <a href={HOME_PATH} className="button is-primary">
    Volver al Inicio
  </a>
);

export default HomeButton;
