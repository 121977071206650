import React, { Component } from "react";
import { Link } from "react-router-dom";

class ItineraryOrder extends Component {
  render() {
    const { field, sort, onClick } = this.props;
    let arrow = sort === "asc" ? "&#8593" : "&#8595";
    return (
      <div className="content is-small has-text-right">
        Ordenar por:
        <Link
          to={"#"}
          onClick={() => onClick("departureTimestamp", sort)}
          className="px-2"
        >
          Salida{" "}
          {field === "departureTimestamp" ? (
            <span
              className="subtitle is-6 ib-primary"
              dangerouslySetInnerHTML={{ __html: arrow }}
            ></span>
          ) : null}
        </Link>
        <Link
          to={"#"}
          onClick={() => onClick("arrivalTimestamp", sort)}
          className="px-2"
        >
          Llegada{" "}
          {field === "arrivalTimestamp" ? (
            <span
              className="subtitle is-6 ib-primary"
              dangerouslySetInnerHTML={{ __html: arrow }}
            ></span>
          ) : null}
        </Link>
        <Link
          to={"#"}
          onClick={() => onClick("duration", sort)}
          className="px-2"
        >
          Duración{" "}
          {field === "duration" ? (
            <span
              className="subtitle is-6 ib-primary"
              dangerouslySetInnerHTML={{ __html: arrow }}
            ></span>
          ) : null}
        </Link>
      </div>
    );
  }
}

export default ItineraryOrder;
