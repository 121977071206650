import React from "react";
import Select from "react-select";

const ReactSelect = ({
  input: { onChange, value },
  name,
  options,
  isLoading,
  isClearable,
  isMulti,
  onInputChange,
  placeholder,
  isDisabled,
  meta: { touched, error, warning },
  autoFocus,
  noOptionsMessage,
  styles,
}) => (
  <div>
    <Select
      isDisabled={isDisabled}
      onChange={onChange}
      onInputChange={onInputChange}
      value={value}
      name={name}
      options={options}
      isClearable={isClearable}
      isMulti={isMulti}
      isLoading={isLoading}
      placeholder={placeholder}
      autoFocus={autoFocus}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={() => "Cargando..."}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={styles}
    />
    <p className="help is-danger">
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </p>
  </div>
);

export default ReactSelect;
