import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, Field, change } from 'redux-form';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import Section from '../../common/Section';
import ItineraryResultHeader from '../itinerary/ItineraryResultHeader';
import PassengerForm from './PassengerForm';
import { isRequired } from '../../../../utils/validators';
import TextInput from '../../../form/TextInput';
import PurchaseDetail from './PurchaseDetail';
import {
  changeStep,
  seatReservation,
  clearPayment,
  getBusiness,
  clearBusiness,
} from '../../../../actions';
import {
  PASSENGERS_STEP_INDEX,
  PASSENGER_COUNTRY_OPTIONS,
  PASSENGER_DOCUMENT_TYPE_DEFAULT,
} from '../../../../config/constants';
import VisaForm from '../payment/VisaForm';
import {
  HOME_PATH,
  PRIVACY_POLICIES_PATH,
  TERMS_AND_CONDITIONS_PATH,
} from '../../../../config/paths';
import ReactSelect from '../../../form/ReactSelect';
import PayWithVisa from '../../../../resources/images/pay_with_visa.png';

class Passengers extends Component {
  static renderPassengers({ fields }) {
    return fields.map((passenger, index) => (
      <PassengerForm passenger={passenger} key={passenger} index={index} />
    ));
  }

  constructor(props) {
    super(props);

    this.state = { requestInvoice: false, isHidden: 'is-hidden' };
  }

  componentDidMount() {
    const { dispatchChangeStep, seatSelections } = this.props;

    if (seatSelections.isEmpty()) {
      history.push(HOME_PATH);
    }

    dispatchChangeStep(PASSENGERS_STEP_INDEX);
  }

  componentWillUnmount() {
    this.props.dispatchClearPayment();
  }

  handlePassengersSubmit = (formValues) => {
    const {
      seatSelections,
      sourceId,
      destinationId,
      date,
      time,
      arrivalDate,
      arrivalTime,
      returnSeatSelections,
      returnDate,
      returnTime,
      returnArrivalDate,
      returnArrivalTime,
      hasReturn,
      dispatchSeatReservation,
      visaSession,
    } = this.props;

    if (!visaSession.isEmpty()) {
      VisaForm.activate();
    } else {
      let business = null;

      if (formValues.invoice) {
        business = {
          id: formValues.business ? formValues.business.value : null,
          businessTaxId: formValues.businessTaxId,
          name: formValues.name,
          idCountryOfOrigin: formValues.idCountryOfOrigin.value,
          addressSummary: formValues.addressSummary,
        };
      }

      // going payload
      let index = 0;

      const reservationRequestList = [];

      seatSelections.forEach((seat) => {
        const { itineraryId, routeId, seatNumber, floorNumber, price } = seat;

        const reservationRequest = {
          itineraryId,
          routeId,
          passenger: {
            customerId: formValues.passengers[index].customerId
              ? formValues.passengers[index].customerId.value
              : null,
            documentTypeId: formValues.passengers[index].documentTypeId.value,
            documentNumber: formValues.passengers[index].documentNumber,
            name: formValues.passengers[index].name,
            lastName: formValues.passengers[index].lastName,
            motherLastName: formValues.passengers[index].motherLastName,
            gender: formValues.passengers[index].gender
              ? formValues.passengers[index].gender.value
              : null,
            email: formValues.passengers[index].email,
            dob: formValues.passengers[index].dob,
            mobilePhone: formValues.passengers[index].mobilePhone,
          },
          seatInformation: {
            seatNumber,
            floorNumber,
            sourceAgencyId: sourceId,
            departureDate: date,
            departureTime: time,
            destinationAgencyId: destinationId,
            arrivalDate: arrivalDate,
            arrivalTime: arrivalTime,
            price,
          },
        };

        reservationRequestList.push(reservationRequest);

        index += 1;
      });

      if (hasReturn) {
        index = 0;

        returnSeatSelections.forEach((seat) => {
          const { itineraryId, routeId, seatNumber, floorNumber, price } = seat;

          const reservationRequest = {
            itineraryId,
            routeId,
            passenger: {
              customerId: formValues.passengers[index].customerId
                ? formValues.passengers[index].customerId.value
                : null,
              documentTypeId: formValues.passengers[index].documentTypeId.value,
              documentNumber: formValues.passengers[index].documentNumber,
              name: formValues.passengers[index].name,
              lastName: formValues.passengers[index].lastName,
              motherLastName: formValues.passengers[index].motherLastName,
              gender: formValues.passengers[index].gender
                ? formValues.passengers[index].gender.value
                : null,
              email: formValues.passengers[index].email,
              dob: formValues.passengers[index].dob,
              mobilePhone: formValues.passengers[index].mobilePhone,
            },
            seatInformation: {
              seatNumber,
              floorNumber,
              sourceAgencyId: destinationId,
              departureDate: returnDate,
              departureTime: returnTime,
              destinationAgencyId: sourceId,
              arrivalDate: returnArrivalDate,
              arrivalTime: returnArrivalTime,
              price,
            },
          };

          reservationRequestList.push(reservationRequest);

          index += 1;
        });
      }

      const newFormValues = {
        reservationRequestList,
        business,
        invoice: formValues.invoice,
        paymentMethod: formValues.paymentMethod,
      };

      dispatchSeatReservation(newFormValues);
    }
  };

  handleNoBusiness = ({ inputValue }) => (
    <div>
      <p>
        <small>
          Ingrese su número de RUC.
          <br />
          Si no existe por favor haga click en el siguiente botón.
        </small>
      </p>
      <br />
      <button
        type="button"
        className="button is-link"
        onClick={() => this.showNewBusinessForm(inputValue)}
      >
        Mi RUC no está registrado
      </button>
    </div>
  );

  showNewBusinessForm = (inputValue) => {
    this.resetBusinessInputs();

    const { dispatchChange } = this.props;
    dispatchChange('Passengers', 'businessTaxId', inputValue);
    dispatchChange('Passengers', 'business', null);

    this.showBusinessDetails();
  };

  resetBusinessInputs = () => {
    const { dispatchChange } = this.props;

    dispatchChange('Passengers', 'name', null);
    dispatchChange('Passengers', 'idCountryOfOrigin', null);
    dispatchChange('Passengers', 'addressSummary', null);
  };

  showBusinessDetails = () => {
    this.setState({ isHidden: '' });
  };

  hideBusinessDetails = () => {
    this.setState({ isHidden: 'is-hidden' });
  };

  onChangeBusiness = (option) => {
    const { dispatchChange } = this.props;

    this.resetBusinessInputs();

    if (option.value) {
      dispatchChange('Passengers', 'businessTaxId', option.businessTaxId);
      dispatchChange('Passengers', 'name', option.name);
      dispatchChange('Passengers', 'addressSummary', option.addressSummary);
      dispatchChange(
        'Passengers',
        'idCountryOfOrigin',
        PASSENGER_COUNTRY_OPTIONS.find(
          (country) =>
            parseInt(country.value) === parseInt(option.idCountryOfOrigin)
        )
      );

      this.showBusinessDetails();
    } else {
      this.hideBusinessDetails();
    }
  };

  onChangeInvoice = (e) => {
    this.setState({ requestInvoice: e.target.checked });
  };

  onInputChangeBusiness = (value) => {
    const { dispatchGetBusiness } = this.props;

    if (value.length > 10) {
      dispatchGetBusiness({ businessTaxId: value });
    }
  };

  render() {
    const {
      handleSubmit,
      loadingReservation,
      visaSession,
      businessOptions,
      loadingBusiness,
    } = this.props;

    const { requestInvoice, isHidden } = this.state;

    let disabled = false;
    let visaForm = null;
    if (!visaSession.isEmpty()) {
      visaForm = <VisaForm session={visaSession.toJS()} />;
    }

    if (loadingReservation) {
      disabled = true;
    }

    let renderBusiness = null;

    if (requestInvoice) {
      renderBusiness = (
        <>
          <div className="field is-grouped">
            <div className="control is-expanded">
              <div className="is-fullwidth">
                <Field
                  name="business"
                  component={ReactSelect}
                  placeholder={`Ingrese el RUC`}
                  options={businessOptions}
                  noOptionsMessage={this.handleNoBusiness}
                  onInputChange={this.onInputChangeBusiness}
                  onChange={this.onChangeBusiness}
                  isLoading={loadingBusiness}
                />
              </div>
            </div>
          </div>
          <div className={`field is-grouped ${isHidden}`}>
            <div className="control is-expanded">
              <div className="is-fullwidth">
                <Field
                  name="businessTaxId"
                  component={TextInput}
                  type="text"
                  placeholder="Número de RUC"
                  validate={[isRequired]}
                />
              </div>
            </div>
            <div className="control is-expanded">
              <div className="is-fullwidth">
                <Field
                  name="name"
                  component={TextInput}
                  type="text"
                  placeholder="Razón Social"
                  validate={[isRequired]}
                />
              </div>
            </div>
          </div>
          <div className={`field is-grouped ${isHidden}`}>
            <div className="control is-expanded">
              <div className="is-fullwidth">
                <Field
                  name="addressSummary"
                  component={TextInput}
                  type="text"
                  placeholder="Dirección"
                  validate={[isRequired]}
                />
              </div>
            </div>
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <Field
                  name="idCountryOfOrigin"
                  component={ReactSelect}
                  placeholder="Seleccione..."
                  options={PASSENGER_COUNTRY_OPTIONS}
                  validate={[isRequired]}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <Fragment>
        <Section>
          <ItineraryResultHeader />

          <div className="container" style={{ marginTop: '1.5rem' }}>
            <div className="columns">
              <div className="column is-2"></div>
              <div className="column is-5">
                <div className="card">
                  <div className="card-content">
                    <form onSubmit={handleSubmit(this.handlePassengersSubmit)}>
                      {/* Start passenger form */}
                      <FieldArray
                        name="passengers"
                        component={Passengers.renderPassengers}
                      />
                      {/* End passenger form */}

                      <hr />

                      <h6 className="title is-6 ib-secondary">
                        ¿Cómo quieres pagar?
                      </h6>

                      <div className="control">
                        <label className="radio">
                          <Field
                            name="paymentMethod"
                            component={TextInput}
                            type="radio"
                            value="visa"
                            validate={[isRequired]}
                          >
                            <img
                              src={PayWithVisa}
                              alt="Paga con Visa"
                              style={{
                                float: 'right',
                                marginTop: '-10px',
                                marginLeft: '10px',
                              }}
                            />
                          </Field>
                        </label>
                      </div>

                      <br />

                      <h6 className="title is-6 ib-secondary">Facturación</h6>

                      <label className="checkbox">
                        <Field
                          name="invoice"
                          component="input"
                          type="checkbox"
                          onChange={this.onChangeInvoice}
                        />{' '}
                        Solicitar factura
                      </label>
                      <br />
                      <br />

                      {renderBusiness}

                      <div className="content">
                        <p>
                          <small>
                            Al darle click en continuar acepta nuestros{' '}
                            <Link
                              to={TERMS_AND_CONDITIONS_PATH}
                              target="_blank"
                            >
                              {' '}
                              términos y condiciones
                            </Link>
                            , y
                            <Link to={PRIVACY_POLICIES_PATH} target="_blank">
                              {' '}
                              política de privacidad.
                            </Link>
                          </small>
                        </p>
                      </div>
                      <div className="field">
                        <div className="control">
                          <button
                            type="submit"
                            className="button is-link is-fullwidth"
                            disabled={disabled}
                          >
                            Continuar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <PurchaseDetail />
              </div>
              <div className="column is-2"></div>
            </div>
          </div>
          {visaForm}
        </Section>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ Booking, Business }) => {
  const seatSelections = Booking.getIn(['seats', 'seatSelections']);

  const passengers = seatSelections
    .map(() => ({
      documentTypeId: PASSENGER_DOCUMENT_TYPE_DEFAULT,
    }))
    .toJS();

  const { id, businessTaxId, name, addressSummary, idCountryOfOrigin } =
    Business.getIn(['current', 'content']);

  const businessOptions = [];

  if (id && name && businessTaxId) {
    businessOptions.push({
      value: id,
      label: `${businessTaxId} - ${name}`,
      name,
      businessTaxId,
      addressSummary,
      idCountryOfOrigin,
    });
  }

  return {
    seatSelections,
    sourceId: Booking.getIn(['search', 'query', 'sourceId']),
    destinationId: Booking.getIn(['search', 'query', 'destinationId']),
    date: Booking.getIn(['search', 'query', 'date']),
    time: Booking.getIn(['search', 'query', 'time']),
    arrivalDate: Booking.getIn(['search', 'query', 'arrivalDate']),
    arrivalTime: Booking.getIn(['search', 'query', 'arrivalTime']),
    returnSeatSelections: Booking.getIn(['seats', 'returnSeatSelections']),
    returnDate: Booking.getIn(['search', 'query', 'returnDate']),
    returnTime: Booking.getIn(['search', 'query', 'returnTime']),
    returnArrivalDate: Booking.getIn(['search', 'query', 'returnArrivalDate']),
    returnArrivalTime: Booking.getIn(['search', 'query', 'returnArrivalTime']),
    hasReturn: Booking.getIn(['search', 'query', 'hasReturn']),
    loadingReservation: Booking.getIn(['seats', 'getSeats']),
    seatReservation: Booking.getIn(['seats', 'seatReservation']),
    visaSession: Booking.getIn(['payment', 'visa', 'session']),
    initialValues: {
      passengers,
    },
    loadingBusiness: Business.getIn(['current', 'loading']),
    businessOptions,
  };
};

const mapDispatchToProps = {
  dispatchChangeStep: changeStep,
  dispatchSeatReservation: seatReservation,
  dispatchClearPayment: clearPayment,
  dispatchGetBusiness: getBusiness,
  dispatchClearBusiness: clearBusiness,
  dispatchChange: change,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'Passengers',
    // validate,
  })(Passengers)
);
