import _ from 'lodash';

const toLowerCase = (value) => _.lowerCase(value);

const toUpperCase = (value) => _.upperFirst(value);

const toUpperFirst = (value) => toUpperCase(toLowerCase(value));

const toBytes = ({ user, password }) => {
  return btoa(`${user}:${password}`);
};

export { toUpperFirst, toBytes, toLowerCase, toUpperCase };
