import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment-timezone";
import Container from "../../common/Container";
import { FULL_DATE_FORMAT } from "../../../../config/locale";
import { toUpperFirst } from "../../../../utils/string";
import BookingNavbar from "../BookingNavbar";

class ItineraryResultHeader extends Component {
  render() {
    const { source, destination, date, returnDate, hasReturn } = this.props;

    let newSource = source;
    let newDestination = destination;
    let newDate = date;

    if (hasReturn) {
      newSource = destination;
      newDestination = source;
      newDate = returnDate;
    }

    const formatedDate = Moment.utc(newDate).clone().format(FULL_DATE_FORMAT);

    return (
      <Container>
        <p className="title is-4 has-text-centered ib-primary">
          {toUpperFirst(newSource)}
          <span
            className="ib-secondary"
            dangerouslySetInnerHTML={{ __html: " &#8594 " }}
          />
          {toUpperFirst(newDestination)}
        </p>
        <p className="subtitle is-6 has-text-centered">
          {toUpperFirst(formatedDate)}
        </p>
        <BookingNavbar />
      </Container>
    );
  }
}

const mapStateToProps = ({ Booking }) => ({
  source: Booking.getIn(["search", "query", "source"]),
  destination: Booking.getIn(["search", "query", "destination"]),
  date: Booking.getIn(["search", "query", "date"]),
  returnDate: Booking.getIn(["search", "query", "returnDate"]),
  hasReturn: Booking.getIn(["search", "query", "hasReturn"]),
});

export default connect(mapStateToProps)(ItineraryResultHeader);
