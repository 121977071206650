import React from 'react';

const Modal = ({ isActive = false, body, onClickCloseModel }) => {
  return (
    <div className={`modal ${isActive}`} id="hModal">
      <div className="modal-background"></div>
      <div className="modal-content">{body}</div>
      <button
        className="modal-close is-large"
        onClick={onClickCloseModel}
        aria-label="close"
      ></button>
    </div>
  );
};

export default Modal;
