import React from 'react';
import Container from './common/Container';
import Section from './common/Section';
import InformationSection from './InformationSection';
import PageTitle from './PageTitle';
import { Link } from 'react-router-dom';
import { BUS_SERVICES_PATH, PARCEL_SERVICES_PATH } from '../../config/paths';

import AboutJpg from './../../resources/images/About_660x440px.jpg';

const About = () => {
  return (
    <>
      <PageTitle
        title="Nosotros"
        subtitle="“Somos una empresa que brinda soluciones efectivas en el transporte de pasajeros y cargo”"
      />
      <Section cssStyle="section bg-white">
        <Container>
          <div className="columns mt-4">
            <div className="column is-4">
              <h4 className="subtitle is-4">Nuestra misión</h4>
              <div className="content">
                <p className="pr-6">
                  Somos una empresa que brinda soluciones efectivas en el
                  transporte de pasajeros y carga, ofreciendo un servicio
                  personalizado que busca elevar la productividad y los
                  estándares de calidad de nuestros clientes.
                </p>
              </div>
            </div>

            <div className="column is-4">
              <h4 className="subtitle is-4">Nuestra visión</h4>
              <div className="content">
                <p className="pr-6">
                  Ser la mejor empresa del norte chico que brinde un eficiente
                  servicio de transporte a nuestros clientes naturales y
                  corporativos.
                </p>
              </div>
            </div>

            <div className="column is-4">
              <h4 className="subtitle is-4">Servicios</h4>
              <div className="content">
                <p>
                  Nuestra política de servicio nos permite satisfacer las
                  necesidades de nuestros clientes de manera eficiente y
                  responsable.
                </p>
                <Link to={BUS_SERVICES_PATH}>
                  <span className="icon has-text-info">
                    <i className="fas fa-angle-right"></i>
                  </span>
                  Bus
                </Link>
                <br />
                <Link to={PARCEL_SERVICES_PATH}>
                  <span className="icon has-text-info">
                    <i className="fas fa-angle-right"></i>
                  </span>
                  Encomiendas
                </Link>
              </div>
            </div>
          </div>

          <div className="columns mt-4">
            <div className="column">
              <h4 className="subtitle is-4">Nuestra historia</h4>

              <p className="pb-4">
                Somos un grupo empresarial con más de 20 años de experiencia en
                el rubro de transportes. En el año 2016 lanzamos nuestra marca
                INSTABUS para ofrecer el mejor servicio a nuestros viajeros del
                norte chico pensando en tres factores de servicio que merecen
                nuestros pasajeros “rapidez, seguridad y modernidad”, allí nace
                nuestro slogan <strong>"VIAJA SEGURO, VIAJA EXPRESS"</strong>.
              </p>
              <p>
                Queremos continuar acercando a familias de todo el norte chico y
                que los viajeros conozcan estos destinos llenos de historia,
                cultura, tradición y diversión. Estamos seguros que quedarán
                encantados con todas nuestras rutas.
              </p>
              <br />
              <button
                className="button is-rounded is-primary"
                onClick={() => {
                  window.open(
                    'https://www.youtube.com/watch?v=T8XhBgbOZ8c',
                    '_blank'
                  );
                }}
              >
                Conoce más aquí &#62;
              </button>
            </div>
            <div className="column">
              <h4 className="subtitle is-4">&nbsp;</h4>
              <a
                href="https://www.youtube.com/watch?v=T8XhBgbOZ8c"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="image-border" src={AboutJpg} alt="Nosotros" />
              </a>
            </div>
          </div>
        </Container>
      </Section>
      <InformationSection />
    </>
  );
};

export default About;
