import React from 'react';

const TextInput = ({
  id,
  input,
  name,
  placeholder,
  type = 'text',
  meta: { touched, error, warning },
  disabled,
  children,
}) => {
  const className = type === 'text' || type === 'number' ? 'input' : null;

  let label = null;
  if (type === 'radio' || type === 'checkbox') {
    label = children;
  }

  return (
    <>
      <input
        {...input}
        className={className}
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
      />
      {label}
      <p className="help is-danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </p>
    </>
  );
};

export default TextInput;
