import React, { Component, Fragment } from "react";
import ScriptTag from "react-script-tag";
import { HOST } from "../../../../config/endpoints";
import {
  VISA_CONFIRMATION_PATH,
  TIMEOUT_STEP_PATH,
} from "../../../../config/paths";

class VisaForm extends Component {
  static activate() {
    const button = document.querySelector(".modal-opener");
    if (button) {
      button.click();
    }
  }

  onScriptLoad = async () => {
    await VisaForm.activate();
  };

  render() {
    const {
      sessionKey,
      purchaseNumber,
      amount,
      cardholderName,
      cardholderLastname,
      cardholderEmail,
    } = this.props.session;

    const { REACT_APP_VISA_MERCHANTID, REACT_APP_VISA_FORM_URL } = process.env;

    const logo =
      "https://development.instabus.com.pe/static/media/instabus.0458b533.png";

    return (
      <Fragment>
        <form
          id="formScript"
          action={`${VISA_CONFIRMATION_PATH}?reservationId=${purchaseNumber}`}
          method="post"
        >
          <ScriptTag
            src={REACT_APP_VISA_FORM_URL}
            data-merchantlogo={logo}
            data-merchantname="Instabus"
            data-sessiontoken={sessionKey}
            data-channel="web"
            data-merchantid={REACT_APP_VISA_MERCHANTID}
            data-formbuttoncolor="#0870ce"
            data-purchasenumber={purchaseNumber}
            data-amount={amount}
            data-expirationminutes="5"
            data-cardholdername={cardholderName}
            data-cardholderlastname={cardholderLastname}
            data-cardholderemail={cardholderEmail}
            data-timeouturl={`${HOST}/${TIMEOUT_STEP_PATH}/${purchaseNumber}`}
            onLoad={this.onScriptLoad}
            onError={() =>
              alert("Hubo un error al cargar el formulario de pago de Visa")
            }
          />
        </form>

        <style global>
          {`
            button.start-js-btn {
              display: none !important;
            }
          `}
        </style>
      </Fragment>
    );
  }
}

export default VisaForm;
