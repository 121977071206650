import React from 'react';

import RestroomsPng from '../../../../resources/images/restrooms.png';

const SeatMapElement = ({
  seatObjectType,
  seatNumber,
  free,
  onClick,
  id,
  selected,
}) => {
  let classes = '';
  let element = null;
  if (seatObjectType === 'SEAT') {
    classes += ' seat';
    element = <span>{seatNumber}</span>;
  }
  if (seatObjectType === 'TV') {
    classes += ' icon';
    element = <i className="fa fa-tv"></i>;
  }
  if (seatObjectType === 'RESTROOMS') {
    classes += ' icon';
    element = <img alt="baño" src={RestroomsPng} />;
  }
  if (seatObjectType === 'PASSAGE') {
    element = null;
  }
  if (selected) {
    classes += ' selected';
  }
  if (!free) {
    classes += ' ocuppied';
  }
  return (
    <div id={id} className={classes} onClick={onClick} role="button">
      {element}
    </div>
  );
};

export default SeatMapElement;
