import React from "react";

const Container = ({ cssStyle = "container", styles = {}, children }) => {
  return (
    <div className={cssStyle} style={styles}>
      {children}
    </div>
  );
};

export default Container;
