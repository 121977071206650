import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import TextInput from '../../../form/TextInput';
import {
  isRequired,
  validateDateOfBirthday,
  validateEmail,
} from '../../../../utils/validators';
import ReactSelect from '../../../form/ReactSelect';
import {
  PASSENGER_GENDER_OPTIONS,
  PASSENGER_DOCUMENT_TYPE_OPTIONS,
  DNI_VALUE,
  PASSENGER_DOCUMENT_TYPE_DEFAULT,
} from '../../../../config/constants';
import { getCustomer, clearCustomer } from '../../../../actions';

class PassengerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: 'is-hidden',
      documentLabel: PASSENGER_DOCUMENT_TYPE_DEFAULT.label,
      documentType: DNI_VALUE,
    };
  }

  componentWillUnmount() {
    this.props.dispatchClearCustomer();
  }

  showNewPassengerForm = (inputValue) => {
    this.resetPassengerInputs();

    const { passenger, dispatchChange } = this.props;
    dispatchChange('Passengers', `${passenger}.documentNumber`, inputValue);
    dispatchChange('Passengers', `${passenger}.customerId`, null);

    this.showPassengerDetails();
  };

  showPassengerDetails = () => {
    this.setState({ isHidden: '' });
  };

  hidePassengerDetails = () => {
    this.setState({ isHidden: 'is-hidden' });
  };

  handleNoPassengers = ({ inputValue }) => (
    <div>
      <p>
        <small>
          Ingrese su {this.state.documentLabel}.
          <br />
          Si no existe por favor haga click en el siguiente botón.
        </small>
      </p>
      <button
        type="button"
        className="button is-link is-fullwidth"
        onClick={() => this.showNewPassengerForm(inputValue)}
      >
        No estoy registrado
      </button>
    </div>
  );

  onInputChangeCustomer = (value) => {
    const { documentType } = this.state;
    const { dispatchGetCustomer } = this.props;
    if (documentType === DNI_VALUE) {
      if (value.length === 8) {
        dispatchGetCustomer({
          documentTypeId: documentType,
          documentNumber: value,
        });
      }
    } else if (value.length >= 4 && value.length <= 15) {
      dispatchGetCustomer({
        documentTypeId: documentType,
        documentNumber: value,
      });
    }
  };

  onDocumentTypeChange = (option) => {
    this.resetPassengerInputs();
    this.props.dispatchChange(
      'Passengers',
      `${this.props.passenger}.customerId`,
      null
    );
    this.setState({
      documentType: option.value,
      documentLabel: option.label,
      isHidden: 'is-hidden',
    });
  };

  onChangePassenger = (option) => {
    const { passenger, dispatchChange } = this.props;
    this.resetPassengerInputs();
    if (option.value) {
      dispatchChange('Passengers', `${passenger}.name`, option.name);
      dispatchChange('Passengers', `${passenger}.lastName`, option.lastName);
      dispatchChange(
        'Passengers',
        `${passenger}.motherLastName`,
        option.motherLastName
      );
      dispatchChange(
        'Passengers',
        `${passenger}.documentNumber`,
        option.documentNumber
      );
      dispatchChange(
        'Passengers',
        `${passenger}.gender`,
        PASSENGER_GENDER_OPTIONS.find(
          (gender) => gender.value === option.gender
        )
      );
      dispatchChange('Passengers', `${passenger}.dob`, option.dob);
      dispatchChange(
        'Passengers',
        `${passenger}.mobilePhone`,
        option.mobilePhone
      );
      dispatchChange('Passengers', `${passenger}.email`, option.email);
      this.showPassengerDetails();
    } else {
      this.hidePassengerDetails();
    }
  };

  resetPassengerInputs = () => {
    const { passenger, dispatchChange } = this.props;
    dispatchChange('Passengers', `${passenger}.name`, null);
    dispatchChange('Passengers', `${passenger}.lastName`, null);
    dispatchChange('Passengers', `${passenger}.documentNumber`, null);
    dispatchChange('Passengers', `${passenger}.gender`, null);
    dispatchChange('Passengers', `${passenger}.dob`, null);
    dispatchChange('Passengers', `${passenger}.mobilePhone`, null);
    dispatchChange('Passengers', `${passenger}.email`, null);
  };

  render() {
    const { passenger, index, customerOptions, loadingCustomer } = this.props;
    const { isHidden, documentLabel } = this.state;
    return (
      <div className="content">
        <h6 className="title is-6 ib-secondary">
          Datos del Pasajero {index + 1}
        </h6>

        <div className="field is-grouped">
          <div className="control is-expanded">
            <div className="select is-fullwidth">
              <Field
                name={`${passenger}.documentTypeId`}
                component={ReactSelect}
                placeholder="Tipo de Documento"
                options={PASSENGER_DOCUMENT_TYPE_OPTIONS}
                onChange={this.onDocumentTypeChange}
                validate={[isRequired]}
              />
            </div>
          </div>
          <div className="control is-expanded">
            <div className="is-fullwidth">
              <Field
                name={`${passenger}.customerId`}
                component={ReactSelect}
                placeholder={`Ingrese su ${documentLabel}`}
                options={customerOptions}
                noOptionsMessage={this.handleNoPassengers}
                onInputChange={this.onInputChangeCustomer}
                onChange={this.onChangePassenger}
                isLoading={loadingCustomer}
              />
            </div>
          </div>
        </div>

        <div className={`field ${isHidden}`}>
          <div className="control">
            <Field
              name={`${passenger}.name`}
              component={TextInput}
              type="text"
              placeholder="Nombres"
              validate={[isRequired]}
            />
          </div>
        </div>

        <div className={`field ${isHidden}`}>
          <div className="control">
            <Field
              name={`${passenger}.lastName`}
              component={TextInput}
              type="text"
              placeholder="Apellido Paterno"
              validate={[isRequired]}
            />
          </div>
        </div>

        <div className={`field ${isHidden}`}>
          <div className="control">
            <Field
              name={`${passenger}.motherLastName`}
              component={TextInput}
              type="text"
              placeholder="Apellido Materno"
              validate={[isRequired]}
            />
          </div>
        </div>

        <div className={`field is-grouped ${isHidden}`}>
          <div className="control is-expanded">
            <div className="is-fullwidth">
              <Field
                name={`${passenger}.documentNumber`}
                component={TextInput}
                type="text"
                placeholder="Número de Documento"
                validate={[isRequired]}
              />
            </div>
          </div>
          <div className="control is-expanded">
            <div className="is-fullwidth">
              <Field
                name={`${passenger}.dob`}
                component={TextInput}
                type="text"
                placeholder="Fecha de Nacimiento"
                validate={[isRequired, validateDateOfBirthday]}
              />
            </div>
          </div>
        </div>

        <div className={`field is-grouped ${isHidden}`}>
          <div className="control is-expanded">
            <div className="is-fullwidth">
              <Field
                name={`${passenger}.email`}
                component={TextInput}
                type="text"
                placeholder="Correo Electrónico"
                validate={[isRequired, validateEmail]}
              />
            </div>
          </div>
          <div className="control is-expanded">
            <div className="is-fullwidth">
              <Field
                name={`${passenger}.mobilePhone`}
                component={TextInput}
                type="number"
                placeholder="Celular"
                validate={[isRequired]}
              />
            </div>
          </div>
        </div>

        <div className={`field ${isHidden}`}>
          <div className="control">
            <div className="select is-fullwidth">
              <Field
                name={`${passenger}.gender`}
                component={ReactSelect}
                placeholder="Género"
                options={PASSENGER_GENDER_OPTIONS}
                validate={[isRequired]}
              ></Field>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Booking, Customer }) => {
  const {
    customerId,
    documentNumber,
    documentTypeId,
    lastName,
    motherLastName,
    name,
    gender,
    dob,
    email,
    mobilePhone,
  } = Customer.getIn(['current', 'content']);

  const customerOptions = [];

  if (customerId && name && lastName) {
    customerOptions.push({
      value: customerId,
      label: `${documentNumber} - ${name} ${lastName}`,
      documentNumber,
      documentTypeId,
      lastName,
      motherLastName,
      name,
      gender,
      dob,
      email,
      mobilePhone,
    });
  }

  return {
    source: Booking.getIn(['search', 'query', 'source']),
    destination: Booking.getIn(['search', 'query', 'destination']),
    date: Booking.getIn(['search', 'query', 'date']),
    returnDate: Booking.getIn(['search', 'query', 'returnDate']),
    loadingCustomer: Customer.getIn(['current', 'loading']),
    customerOptions,
  };
};

const mapDispatchToProps = {
  dispatchGetCustomer: getCustomer,
  dispatchClearCustomer: clearCustomer,
  dispatchChange: change,
};

export default connect(mapStateToProps, mapDispatchToProps)(PassengerForm);
