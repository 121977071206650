import {
  ITINERARIES_STEP_PATH,
  PASSENGERS_STEP_PATH,
  CONFIRMATION_STEP_PATH,
  DESTINATIONS_PATH,
  AGENCY_PATH,
} from './paths';

export const ITINERARY_FILTER_OPTIONS = [
  {
    value: '00:00:00-06:00:00',
    label: 'Antes de las 6:00am',
  },
  {
    value: '06:00:00-12:00:00',
    label: 'De 6:00am a 12:00pm',
  },
  {
    value: '12:00:00-18:00:00',
    label: 'De 12:00pm a 06:00pm',
  },
  {
    value: '18:00:00-23:59:59',
    label: 'Después de las 06:00pm',
  },
];

export const ITINERARIES_STEP_INDEX = 0;
export const PASSENGERS_STEP_INDEX = 1;
export const CONFIRMATION_STEP_INDEX = 2;
export const generateSteps = (searchParams) => [
  {
    title: 'Horarios',
    path: `${ITINERARIES_STEP_PATH}/${searchParams}`,
    icon: 'calendar',
  },
  {
    title: 'Datos de pasajero(s)',
    path: PASSENGERS_STEP_PATH,
    icon: 'user',
  },
  {
    title: 'Confirmación',
    path: CONFIRMATION_STEP_PATH,
    icon: 'check',
  },
];

export const PASSENGER_DOCUMENT_TYPE_DEFAULT = { value: 1, label: 'DNI' };

export const PASSENGER_DOCUMENT_TYPE_OPTIONS = [
  { value: 1, label: 'DNI' },
  { value: 3, label: 'CE' },
  { value: 4, label: 'Pasaporte' },
];

export const PASSENGER_COUNTRY_DEFAULT = {
  value: 1,
  label: 'Peruano',
};

export const PASSENGER_COUNTRY_OPTIONS = [
  {
    value: 1,
    label: 'Peruano',
  },
  {
    value: 2,
    label: 'Extranjero',
  },
];

export const PASSENGER_GENDER_OPTIONS = [
  {
    value: 1,
    label: 'Masculino',
  },
  {
    value: 2,
    label: 'Femenino',
  },
];

export const DNI_PATTERN = /^\d{8}$/;
export const CARNET_EXTRANJERIA_PATTERN = /^[a-zA-Z0-9]{1,12}$/;
export const PASAPORTE_PATTERN = /^[a-zA-Z0-9]{1,12}$/;

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const DATE_PATTERN = /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;

export const DNI_VALUE = 1;
export const CE_VALUE = 3;
export const PAS_VALUE = 4;

export const CAROUSEL_DESTINATIONS_LIST = [
  {
    id: 'product_0',
    destinationsPath: DESTINATIONS_PATH,
    agenciesPath: AGENCY_PATH,
    className: 'destination-lima',
    title: 'Lima',
    subtitle: 'Diviértete en el corazón del Perú',
    address: 'Jr. Luna Pizarro #251, La Victoria',
    timetable: 'De: 5:00 a.m. a 9:00 p.m. / 954158516',
    availableServices: 'Pasajes - Encomienda - Cargo',
  },
  {
    id: 'product_1',
    destinationsPath: DESTINATIONS_PATH,
    agenciesPath: AGENCY_PATH,
    className: 'destination-huaral',
    title: 'Huaral',
    subtitle: 'Descubre la “Capital de la Agricultura”',
    address: 'Prolongacion El Palmo #216',
    timetable: 'De: 7:00 a.m. a 7:00 p.m. / 952305989',
    availableServices: 'Encomienda - Cargo',
  },
  {
    id: 'product_2',
    destinationsPath: DESTINATIONS_PATH,
    agenciesPath: AGENCY_PATH,
    className: 'destination-huacho',
    title: 'Huacho',
    subtitle: 'Visita la ”Capital de la Hospitalidad”',
    address: 'Av. Panamericana Norte #1181',
    timetable: 'De: 4:00 a.m. a 7:00 p.m. / 940377184',
    availableServices: 'Pasajes - Encomienda - Cargo',
  },
  {
    id: 'product_3',
    destinationsPath: DESTINATIONS_PATH,
    agenciesPath: AGENCY_PATH,
    className: 'destination-supe',
    title: 'Supe',
    subtitle: 'Sorpréndete con la Ciudadela de Caral',
    address: 'Av. Francisco Vidal #796',
    timetable: 'De: 3:00 a.m. a 7:00 p.m. / 940377184',
    availableServices: 'Pasajes - Encomienda - Cargo',
  },
  {
    id: 'product_4',
    destinationsPath: DESTINATIONS_PATH,
    agenciesPath: AGENCY_PATH,
    className: 'destination-barranca',
    title: 'Barranca',
    subtitle: 'Broncéate en sus encantadoras playas',
    address: 'Calle Leoncio Prado #269',
    timetable: 'De: 3:00 a.m. a 7:00 p.m. / 940375119',
    availableServices: 'Pasajes - Encomienda - Cargo',
  },
  {
    id: 'product_5',
    destinationsPath: DESTINATIONS_PATH,
    agenciesPath: AGENCY_PATH,
    className: 'destination-paramonga',
    title: 'Paramonga',
    subtitle: 'Explora la Fortaleza de Paramonga',
    address: 'Av. Francisco Vidal #921',
    timetable: 'De: 3:00 a.m. a 7:00 p.m. / 940374140',
    availableServices: 'Pasajes - Encomienda - Cargo',
  },
];
