import React from "react";
import Floors from "./Floors";

const SeatMap = ({
  floors,
  hasReturn,
  seatSelections,
  returnSeatSelections,
  seatSelectionChangesInProgress,
  itineraryId,
  routeId,
  handleSeatSelection,
  handleSeatDeselection,
}) => (
  <div>
    <Floors
      floors={floors}
      hasReturn={hasReturn}
      seatSelections={seatSelections}
      returnSeatSelections={returnSeatSelections}
      seatSelectionChangesInProgress={seatSelectionChangesInProgress}
      itineraryId={itineraryId}
      routeId={routeId}
      handleSeatSelection={handleSeatSelection}
      handleSeatDeselection={handleSeatDeselection}
    />
  </div>
);

export default SeatMap;
