import React from 'react';
import Container from './common/Container';
import Section from './common/Section';
import InformationSection from './InformationSection';
import SearchFormSection from './SearchFormSection';

import EncomiendaRegularJpg from '../../resources/images/Encomienda_Regular_480x480px.jpg';
import EncomiendaExpressJpg from '../../resources/images/Encomienda_Express_480x480px.jpg';
import EncomiendaCargoJpg from '../../resources/images/Encomienda_Cargo_480x480px.jpg';

const ParcelServices = () => {
  return (
    <>
      <SearchFormSection />
      <Section cssStyle="section bg-white">
        <Container>
          <div className="container">
            <div className="columns">
              <div className="column is-12">
                <p className="title is-3 is-spaced">
                  Envíos rápidos y seguros con Instabus
                </p>
                <p className="pb-4">
                  ¿Necesitas enviar algo con urgencia o estás emprendiendo un
                  negocio y quieres llegar a más clientes? No te preocupes,
                  nosotros lo llevamos por ti. En Instabus nos aseguramos de
                  llevar tus encomiendas y cargo a cualquiera de nuestros
                  destinos.
                </p>
                <p className="pb-4">
                  Sabemos lo importante que son tus encomiendas, así que
                  llevaremos todo lo que necesites con el compromiso de
                  trasladar cuidadosamente todos tus envíos de forma rápida y
                  segura hasta su destino en el tiempo establecido. Este
                  servicio sólo lo puedes encontrar en Instabus.
                </p>
                <p className="pb-4">
                  Recuerda que toda la mercadería debe contar con una guía de
                  remisión o boleta. Las encomiendas frágiles deben estar
                  correctamente embaladas y señalizadas. También ofrecemos el
                  servicio de embalaje.
                </p>
              </div>
            </div>
            <div className="columns mt-4">
              <div className="column is-8">
                <div className="content">
                  <p className="title is-4">Servicio Regular</p>
                  <p>
                    Realiza tus envíos de encomiendas de manera segura y
                    efectiva con nuestro servicio económico.
                  </p>
                  <p className="title is-6">
                    Contamos con las siguientes destinos:{' '}
                  </p>
                  <p>
                    Norte chico: Lima - Huaral - Chancay - Huacho – Supe -
                    Barranca – Paramonga
                  </p>
                  <p>Sur chico: Cañete - Chincha - Pisco - Ica</p>
                  <div className="content">
                    <ul>
                      <li>Entrega en 24 horas</li>
                      <li>Manipulamos con mucho cuidado los envíos frágiles</li>
                      <li>Compromiso y seguridad en nuestro servicio</li>
                    </ul>
                  </div>

                  <button className="button is-rounded is-primary">
                    Rastrea tu envío &#62;
                  </button>
                </div>
              </div>
              <div className="column">
                <img
                  className="image-border"
                  src={EncomiendaRegularJpg}
                  alt="Encomienda Regular"
                />
              </div>
            </div>
            <div className="columns mt-4">
              <div className="column is-8">
                <div className="content">
                  <p className="title is-4">Servicio Express</p>
                  <p>
                    En Instabus sabemos lo que necesitas, por eso queremos
                    ayudarte a llevar y entregar tus encomiendas el mismo día
                    que lo envías. Nuestro servicio express lo hace de manera
                    rápida y segura.
                  </p>
                  <p className="title is-6">
                    Contamos con las siguientes destinos:{' '}
                  </p>
                  <p>
                    Norte chico: Lima - Huaral - Chancay - Huacho – Supe -
                    Barranca – Paramonga
                  </p>
                  <p>Sur chico: Cañete - Chincha - Pisco - Ica</p>
                  <div className="content">
                    <ul>
                      <li>Entrega el mismo día de envío</li>
                      <li>Manipulamos con mucho cuidado los envíos frágiles</li>
                      <li>Compromiso y seguridad en nuestro servicio</li>
                    </ul>
                  </div>

                  <button
                    className="button is-rounded is-primary"
                    onClick={() => {
                      window.open(
                        'https://www.youtube.com/watch?v=qwpWHB7z2ZE',
                        '_blank'
                      );
                    }}
                  >
                    Conoce más aquí &#62;
                  </button>
                </div>
              </div>
              <div className="column">
                <a
                  href="https://www.youtube.com/watch?v=qwpWHB7z2ZE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="image-border"
                    src={EncomiendaExpressJpg}
                    alt="Encomienda Express"
                  />
                </a>
              </div>
            </div>

            <div className="columns mt-4">
              <div className="column is-8">
                <div className="content">
                  <p className="title is-4">Servicio de Cargo de Instabus</p>
                  <p>
                    Nos encargamos de llevar toda tu carga. Recogemos todo lo
                    que necesites enviar desde tu domicilio a cualquiera de
                    nuestros destinos. Te mantenemos informado ya que realizamos
                    un control eficiente “puerta a puerta” de la carga.
                  </p>
                  <p>Te ofrecemos los siguientes servicios: </p>
                  <p className="title is-6">Traslado de mudanza</p>
                  <p>
                    Múdate sin preocupaciones, en Instabus nos haremos cargo de
                    llevar todo lo que necesites sin estrés para ti y los tuyos.
                  </p>
                  <p className="title is-6">Servicio pago a destino</p>
                  <p>
                    En Instabus te ofrecemos este servicio para que la persona
                    que reciba la carga realice el pago del servicio.
                  </p>
                  <p className="title is-6">
                    Servicio de entrega con retorno de cargo
                  </p>
                  <p>
                    Este servicio permite enviar y recibir lo que necesites. En
                    Instabus el costo del retorno es gratuito.
                  </p>

                  <button className="button is-rounded is-primary">
                    Rastrea tu envío &#62;
                  </button>
                </div>
              </div>
              <div className="column">
                <img
                  className="image-border"
                  src={EncomiendaCargoJpg}
                  alt="Encomienda Cargo"
                />
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <InformationSection />
    </>
  );
};

export default ParcelServices;
