import React, { Component, Fragment } from "react";
import _ from "lodash";
import Seat from "./Seat";

class Floor extends Component {
  constructor(props) {
    super(props);
    this.renderRows = this.renderRows.bind(this);
    this.renderColumns = this.renderColumns.bind(this);
    this.findSeat = this.findSeat.bind(this);
    this.renderSeatMapElements = this.renderSeatMapElements.bind(this);
  }

  findSeat({ column, row }) {
    const {
      floor: { seatList },
    } = this.props;
    return _.find(seatList, { column: column.toString(), row: row.toString() });
  }

  renderSeatMapElements() {
    return this.renderRows();
  }

  renderRows() {
    const {
      floor: { numberOfRows },
    } = this.props;
    return _.range(numberOfRows).map((row) => (
      <div className="columns is-mobile is-gapless" key={row}>
        {this.renderColumns({ row })}
      </div>
    ));
  }

  renderColumns({ row }) {
    const {
      floor: { numberOfColumns },
    } = this.props;
    return _.range(numberOfColumns).map((column) => {
      let columnFirst = null;
      if (column === 0) {
        columnFirst = (
          <div className="column" key={column}>
            <div className="no-seat">&nbsp;</div>
          </div>
        );
      }
      if (column === 0 && row === 4) {
        columnFirst = (
          <div className="column" key={`circle${column}`}>
            <span className="icon">
              <i className="fa fa-circle"></i>
            </span>
          </div>
        );
      }
      let component = null;
      const seat = this.findSeat({ column: column + 1, row: row + 1 });
      if (seat) {
        component = (
          <div
            className={`column ${column === 0 ? "seat-container" : ""}`}
            key={`${column}${row}`}
          >
            {this.renderSeat(seat)}
          </div>
        );
      }
      return (
        <Fragment key={`column${column}`}>
          {columnFirst}
          {component}
        </Fragment>
      );
    });
  }

  renderSeat(seat) {
    const { seatNumber, price, status, seatObjectType } = seat;
    const {
      itineraryId,
      routeId,
      hasReturn,
      seatSelections,
      returnSeatSelections,
      seatSelectionChangesInProgress,
      handleSeatSelection,
      handleSeatDeselection,
      floor: { floorNumber },
    } = this.props;
    const seatId = `${itineraryId}-${routeId}-${seatNumber}`;
    return (
      <Seat
        seatNumber={seatNumber}
        floorNumber={floorNumber}
        price={price}
        status={status}
        seatObjectType={seatObjectType}
        id={seatId}
        hasReturn={hasReturn}
        seatSelections={seatSelections}
        returnSeatSelections={returnSeatSelections}
        seatSelectionChangesInProgress={seatSelectionChangesInProgress}
        itineraryId={itineraryId}
        routeId={routeId}
        handleSeatSelection={handleSeatSelection}
        handleSeatDeselection={handleSeatDeselection}
      />
    );
  }

  render() {
    return this.renderSeatMapElements();
  }
}

export default Floor;
