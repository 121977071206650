import React from 'react';
import Container from './common/Container';
import Section from './common/Section';
import InformationSection from './InformationSection';
import PageTitle from './PageTitle';

const Contact = () => {
  return (
    <>
      <PageTitle
        title="Agencias"
        subtitle="“Comunícate con nosotros y recibe la mejor atención personalizada”"
      />
      <Section cssStyle="section bg-white">
        <Container>
          <div className="columns">
            <div className="column is-12">
              <p className="pb-4">
                En Instabus te atenderemos con mucho gusto, puedes llamarnos a
                nuestra central de atención al cliente 01 423-6338, escribirnos
                a <a href="mailto:info@instabus.com.pe">info@instabus.com.pe</a>{' '}
                o al 965 762 927 (WhatsApp) y aclararemos todas tus dudas.
              </p>
              <p>
                También puedes comunicarte a cualquiera de nuestras agencias en
                Lima, Huacho, Barranca, Supe y Paramonga. En Instabus estamos
                atentos a escucharte y brindarte el mejor servicio.
              </p>
            </div>
          </div>

          <div className="columns mt-4">
            <div className="column">
              <h4 className="subtitle is-4">Agencias</h4>
              <div className="content">
                <h5 className="subtitle is-5">Norte chico</h5>
                <ul>
                  <li className="mb-5">
                    {' '}
                    <strong>Lima</strong>
                    <br />
                    Dirección: Jr. Luna Pizarro #251 – La Victoria
                    <br />
                    Teléfono: 954158516 - 922244814
                    <br />
                    Horario de Pasajes: Lunes a Domingo: 5:00 a.m. a 9:00 p.m.
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 7:00 a.m. a 9:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    {' '}
                    <strong>José Galvez</strong>
                    <br />
                    Dirección: Av. José Galvez #492 - La Victoria
                    <br />
                    Teléfono: 940377187
                    <br />
                    Horario de Pasajes: Lunes a Domingo: 8:00 a.m. a 8:00 p.m.
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 8:00 a.m. a 8:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    {' '}
                    <strong>Los Olivos</strong>
                    <br />
                    Dirección: Av. Alfredo Mendiola #3889 - Los Olivos
                    <br />
                    Teléfono: 940381362
                    <br />
                    Horario de Pasajes: Lunes a Domingo: 6:30 a.m. a 6:30 p.m.
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 6:30 a.m. a 6:30
                    p.m.
                  </li>
                  <li className="mb-5">
                    <strong>Huaral</strong>
                    <br />
                    Dirección: Prolongacion El Palmo #216 - Urb. Victoria Baja
                    <br />
                    Teléfono: 952305989
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 7:00 a.m. a 7:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    <strong>Huacho</strong>
                    <br />
                    Dirección: Av. Panamericana Norte #1181 – Referencia Hotel
                    Gool
                    <br />
                    Teléfono: 940377184
                    <br />
                    Horario de Pasajes: Lunes a Domingo: 4:00 a.m. a 7:00 p.m.
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 7:00 a.m. a 7:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    {' '}
                    <strong>Supe</strong>
                    <br />
                    Dirección: Av. Francisco Vidal #796
                    <br />
                    Teléfono: 940375127
                    <br />
                    Horario de Pasajes: Lunes a Domingo: 3:00 a.m. a 7:00 p.m.
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 7:00 a.m. a 7:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    <strong>Barranca</strong>
                    <br />
                    Dirección: Calle Leoncio Prado #269
                    <br />
                    Teléfono: 940375119
                    <br />
                    Horario de Pasajes: Lunes a Domingo: 3:00 a.m. a 7:00 p.m.
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 7:00 a.m. a 7:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    {' '}
                    <strong>Paramonga</strong>
                    <br />
                    Dirección: Av. Francisco Vidal #921
                    <br />
                    Teléfono: 940374140
                    <br />
                    Horario de Pasajes: Lunes a Domingo: 3:00 a.m. a 7:00 p.m.
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 7:00 a.m. a 7:00
                    p.m.
                  </li>
                </ul>

                <h5 className="subtitle is-5">Sur chico</h5>
                <ul>
                  <li className="mb-5">
                    {' '}
                    <strong>Cañete</strong>
                    <br />
                    Dirección: Jr. O'Higgins #116 - San Vicente, Cañete.
                    <br />
                    Teléfono: 960998143
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 9:00 a.m. a 6:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    {' '}
                    <strong>Chincha</strong>
                    <br />
                    Dirección: Calle Ernesto Velit #107. Referencia: Al lado de
                    la Agencia Soyuz
                    <br />
                    Teléfono: 933016937 - 955981833
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 9:00 a.m. a 6:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    {' '}
                    <strong>Pisco</strong>
                    <br />
                    Dirección: Calle Callao #544 Cercado, Pisco
                    <br />
                    Teléfono: 982125091
                    <br />
                    Horario de Encomiendas: Lunes a Domingo:9:00 a.m. a 6:00
                    p.m.
                  </li>
                  <li className="mb-5">
                    <strong>Ica</strong>
                    <br />
                    Dirección: Urbanización Residencial Virgen del Chapi MZ C
                    Lote 2. Referencia: Última cuadra de la Av. La Hueelga
                    (Camino a Huacachina)
                    <br />
                    Teléfono: 967585907 - 991748725
                    <br />
                    Horario de Encomiendas: Lunes a Domingo: 9:00 a.m. a 6:00
                    p.m.
                  </li>
                </ul>
              </div>
            </div>
            <div className="column">
              <h4 className="subtitle is-4">&nbsp;</h4>
              <iframe
                className="image-border"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3914.977698137318!2d-77.6125176485383!3d-11.115038792053527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9106df09a17ca255%3A0x380fc1f1a14c5a09!2sFrancisco%20Vidal%20921%2C%20Huacho%2015136!5e0!3m2!1sen!2spe!4v1600754711216!5m2!1sen!2spe"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="agencies"
              ></iframe>
            </div>
          </div>
        </Container>
      </Section>
      <InformationSection />
    </>
  );
};

export default Contact;
